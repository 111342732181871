import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';

import { IconDollarLine } from '@assets/images/svg';
import { IBuyCurrencyEvent } from '@typings';

import { BaseEvent, IEventItemProps } from './EventBase';
import s from './EventBase.scss';

interface IBuyCurrencyProps extends IEventItemProps<IBuyCurrencyEvent> {}

export const EventBuyCurrency = ({ event, animated }: IBuyCurrencyProps) => {
    const to = `/supporters/${event.userId}`;

    return (
        <BaseEvent
            animated={animated}
            event={event}
            color={s.green}
            icon={<IconDollarLine />}
            user={<Link to={to}>{event.userName || t('common.anonymous')}</Link>}
            action={t('events.purchased')}
            subject={event.packDescription}
        />
    );
};
