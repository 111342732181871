import { Logger } from '@hyperclap/ui';

import {
    IAlertStyle,
    IAlertTypeItem,
    ISendTestBonusEarnedParams,
    ISendTestBuyCurrencyParams,
    ISendTestNewSupporterParams,
    TAlertsList,
    TAlertStyleList,
} from '@typings';

import { baseApi } from './base.api';

const logger = new Logger({ target: 'AlertsApi', showTimestamp: true });
logger.trace(`Configure Alerts API`);

export const alertsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Alerts_List',
            'AlertStyle_List',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            loadAlerts: build.query<TAlertsList, void>({
                query: () => ({
                    url: 'alert',
                    method: 'GET',
                }),
                providesTags: ['Alerts_List'],
            }),

            createAlertType: build.mutation<IAlertTypeItem, Omit<IAlertTypeItem, 'id'>>({
                query: (params: IAlertTypeItem) => ({
                    url: `alert`,
                    method: 'POST',
                    body: params,
                }),
                invalidatesTags: ['Alerts_List'],
            }),

            updateAlertType: build.mutation<IAlertTypeItem, IAlertTypeItem>({
                query: (params: IAlertTypeItem) => ({
                    url: `alert/${params.id}`,
                    method: 'PUT',
                    body: params,
                }),
                invalidatesTags: ['Alerts_List'],
            }),

            loadAlertStyles: build.query<TAlertStyleList, void>({
                query: () => ({
                    url: 'alert-style',
                    method: 'GET',
                }),
                providesTags: ['AlertStyle_List'],
            }),

            createAlertStyle: build.mutation<IAlertStyle, Partial<IAlertStyle>>({
                query: (params: Partial<IAlertStyle>) => ({
                    url: `alert-style`,
                    method: 'POST',
                    body: params,
                }),
                invalidatesTags: ['AlertStyle_List'],
            }),

            updateAlertStyle: build.mutation<IAlertStyle, Partial<IAlertStyle>>({
                query: (params: Partial<IAlertStyle>) => ({
                    url: `alert-style/${params.id}`,
                    method: 'PUT',
                    body: params,
                }),
                invalidatesTags: ['AlertStyle_List'],
            }),

            sendTestBonusEarned: build.mutation<void, ISendTestBonusEarnedParams>({
                query: (params: ISendTestBonusEarnedParams) => ({
                    url: `alert/test/bonus-earned`,
                    method: 'POST',
                    body: params,
                }),
            }),

            sendTestBuyCurrency: build.mutation<void, ISendTestBuyCurrencyParams>({
                query: (params: ISendTestBuyCurrencyParams) => ({
                    url: `alert/test/buy-currency`,
                    method: 'POST',
                    body: params,
                }),
            }),

            sendTestNewSupporter: build.mutation<void, ISendTestNewSupporterParams>({
                query: (params: ISendTestNewSupporterParams) => ({
                    url: `alert/test/new-supporter`,
                    method: 'POST',
                    body: params,
                }),
            }),
        }),
    });
