import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { isStickerSentEvent } from '@common';
import { NotificationType } from '@contexts';
import { useApi, useCurrentUser, useMute, useNotifications, useWebSocket } from '@hooks';
import { EventsFilterPreset } from '@hooks/app/events/Events';
import { IStickerRelatedEvent, IStickerSentEvent, TMemeAlertsEvent, TWSMessage, WSMessageType } from '@typings';

export const useRawEvents = () => {
    const { currentUser, noCurrentUser } = useCurrentUser();
    const {
        events: {
            useLoadEventsQuery,
            useLazyMarkAsReadQuery,
        },
        stickers: {
            useLazyReplayStickerQuery,
            useLazyToggleStickerDisableStateQuery,
            useApproveStickerOnStreamMutation,
            useDeclineStickerOnStreamMutation,
        },
    } = useApi();

    const {
        muteUser,
        unmuteUser,
    } = useMute({ streamerChannelName: currentUser?.id });

    const {
        subscribe,
        unsubscribe,
    } = useWebSocket({ clientId: 'memealerts-events' });

    const {
        notify,
    } = useNotifications();

    const {
        t,
    } = useTranslation();

    const [replaySticker] = useLazyReplayStickerQuery();

    const [toggleStickerDisableState] = useLazyToggleStickerDisableStateQuery();
    const [approveStickerOnStream] = useApproveStickerOnStreamMutation();
    const [declineStickerOnStream] = useDeclineStickerOnStreamMutation();

    const [markAsRead] = useLazyMarkAsReadQuery();

    const [events, setEvents] = useState<TMemeAlertsEvent[]>([]);
    const [loadedEvents, setLoadedEvents] = useState<TMemeAlertsEvent[]>([]);
    const [filters, setFilters] = useState<Array<EventsFilterPreset>>([
        EventsFilterPreset.PURCHASES,
        EventsFilterPreset.BONUSES,
        EventsFilterPreset.STICKERS,
        EventsFilterPreset.MEME_CANNON,
        EventsFilterPreset.FULLSCREEN,
        EventsFilterPreset.MODERATION,
    ]);

    const { data: fetchedEvents, isFetching } = useLoadEventsQuery({ limit: 100, filters }, { refetchOnMountOrArgChange: true });

    const eventsRef = useRef(events);

    const updateApprovalStatus = (updatedEvent: IStickerRelatedEvent) => {
        setEvents([...events].map(
            (e) => isStickerSentEvent(e) && e._id === updatedEvent.id
                ? { ...e, isPreModerationNeeded: updatedEvent.isPreModerationNeeded, preModerationResult: updatedEvent.preModerationResult }
                : e,
        ));
        setLoadedEvents([...loadedEvents].map(
            (e) => isStickerSentEvent(e) && e._id === updatedEvent.id
                ? { ...e, isPreModerationNeeded: updatedEvent.isPreModerationNeeded, preModerationResult: updatedEvent.preModerationResult }
                : e,
        ));
    };

    const hideEvent = (updatedEvent: IStickerRelatedEvent) => {
        setEvents([...events].filter((e) => e._id !== updatedEvent.id));
        setLoadedEvents([...loadedEvents].filter((e) => e._id !== updatedEvent.id));
    };

    const changeFilters = (val: Array<EventsFilterPreset>) => setFilters(val);

    const onMessageHandler = (message: TWSMessage, callback?:(data: unknown) => void) => {
        const { type, data } = message;

        if (type === WSMessageType.EVENT_CREATED) {
            if (callback) {
                callback({ wasRead: true });
            }

            const event = data.event;
            event.id = data.eventId ?? v4();
            event._id = event.id;

            if (
                !eventsRef.current.some((e) => e._id === event._id) &&
                !loadedEvents.some((e) => e._id === event._id)
            ) {
                setEvents([event].concat(eventsRef.current));
            }
        }
    };

    const replayClick = async (event: TMemeAlertsEvent) => {
        await replaySticker(event._id);
    };

    const toggleDisableState = async (event: IStickerSentEvent) => {
        const updatedEvent = (await toggleStickerDisableState(event._id).unwrap()) as IStickerSentEvent;
        setEvents([...events].map(
            (e) => isStickerSentEvent(e) && e.stickerId === updatedEvent.stickerId
                ? { ...e, isStickerDisabled: updatedEvent.isStickerDisabled }
                : e,
        ));
        setLoadedEvents([...loadedEvents].map(
            (e) => isStickerSentEvent(e) && e.stickerId === updatedEvent.stickerId
                ? { ...e, isStickerDisabled: updatedEvent.isStickerDisabled }
                : e,
        ));
    };

    const toggleMuteState = async (event: IStickerSentEvent) => {
        let muteValue: boolean;

        if (event.isUserMutedByStreamer) {
            await unmuteUser(event.userId);
            muteValue = false;
            notify(t('mute.user-is-unblocked'), NotificationType.SUCCESS);
        } else {
            await muteUser(event.userId);
            muteValue = true;
            notify(t('mute.user-is-blocked'), NotificationType.SUCCESS);
        }

        setEvents([...events].map(
            (e) => isStickerSentEvent(e) && e.userId === event.userId
                ? { ...e, isUserMutedByStreamer: muteValue }
                : e,
        ));
        setLoadedEvents([...loadedEvents].map(
            (e) => isStickerSentEvent(e) && e.userId === event.userId
                ? { ...e, isUserMutedByStreamer: muteValue }
                : e,
        ));
    };

    const approveOnStream = async (event: IStickerRelatedEvent) => {
        const updatedEvent = (await approveStickerOnStream(event._id).unwrap()) as IStickerRelatedEvent;
        updateApprovalStatus(updatedEvent);
    };

    const declineOnStream = async (event: IStickerRelatedEvent) => {
        const updatedEvent = (await declineStickerOnStream(event._id).unwrap()) as IStickerRelatedEvent;
        hideEvent(updatedEvent);
    };

    useEffect(() => {
        eventsRef.current = events;
    }, [events]);

    useEffect(() => {
        if (fetchedEvents) {
            setLoadedEvents(fetchedEvents as TMemeAlertsEvent[]);
        }
    }, [fetchedEvents]);

    useEffect(() => {
        if (currentUser) {
            subscribe(currentUser.id, onMessageHandler);
        }

        return () => {
            if (currentUser) {
                unsubscribe(currentUser.id, onMessageHandler);
            }
        };
    }, [currentUser]);

    return {
        currentUser,
        unauthorized: noCurrentUser,
        loadedEvents,
        events,
        isLoading: isFetching,
        markAsRead,

        replayClick,
        toggleDisableState,
        toggleMuteState,
        changeFilters,
        approveOnStream,
        declineOnStream,
    };
};
