import { TSortConditions } from '@typings';

export enum SupportersFilterPreset {
    ALL,
    BANNED,
}

export interface ISupporter {
    _id: string;
    supporterName: string;
    supporterAvatar: string;
    supporterLink: string;
    supporterId: string;
    balance: number;
    spent: number;
    purchased: number;
    joined: number;
    newbieActionUsed: boolean;
    welcomeBonusEarned: boolean;
    mutedByStreamer: boolean;
}

export interface ISupportersFilters {
    filters?: Array<SupportersFilterPreset>;
    query?: string;
    sort?: TSortConditions<ISupporter>;
}
