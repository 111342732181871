import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';

import { IconDollarLine } from '@assets/images/svg';
import { declensionUserCurrency } from '@common';
import { IUser, IWelcomeBonusEarnedEvent } from '@typings';

import { BaseEvent, IEventItemProps } from './EventBase';
import s from './EventBase.scss';

interface IWelcomeBonusEarnedProps extends IEventItemProps<IWelcomeBonusEarnedEvent> {
    currentUser?: IUser;
}

export const EventWelcomeBonusEarned = ({ currentUser, event, animated }: IWelcomeBonusEarnedProps) => {
    const to = `/supporters/${event.userId}`;

    const subject = currentUser
        ? `${event.bonusValue} ${declensionUserCurrency(event.bonusValue, currentUser).toLowerCase()}`
        : '';

    return (
        <BaseEvent
            animated={animated}
            event={event}
            color={s.blue}
            icon={<IconDollarLine />}
            user={<Link to={to}>{event.userName || t('common.anonymous')}</Link>}
            action={t('events.earned-bonus')}
            subject={subject}
        />
    );
};
