import { Loader, ScrollableArea } from '@hyperclap/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconBellSolid } from '@assets/images/svg';
import { IStickerRelatedEvent, IUser, TMemeAlertsEvent } from '@typings';
import { Empty, EventItem } from '@views';

import s from './EventsList.scss';


interface IEventsListProps {
    currentUser?: IUser;
    events: Array<TMemeAlertsEvent>;
    newEvents: Array<TMemeAlertsEvent>;
    loading?: boolean;

    onStickerClick: (stickerId: string) => void
    onReplayClick(event: TMemeAlertsEvent): void;
    onToggleDisableStateClick(event: TMemeAlertsEvent): void;
    onToggleMuteStateClick(event: TMemeAlertsEvent): void;
    onApprove(event: IStickerRelatedEvent): void;
    onDecline(event: IStickerRelatedEvent): void;
}

export const EventsList = (props: IEventsListProps) => {
    const { t } = useTranslation();
    const {
        events,
        newEvents,
        currentUser,
        loading,

        onStickerClick,
        onReplayClick,
        onToggleDisableStateClick,
        onToggleMuteStateClick,
        onApprove,
        onDecline,
    } = props;

    return (
        <ScrollableArea>
            <div className={s.scrollableContentArea}>
                {
                    events.length > 0
                        ? <>
                            {newEvents.map((event) => <EventItem
                                animated
                                key={event._id}
                                currentUser={currentUser}
                                event={event}
                                onStickerClick={onStickerClick}
                                onReplayClick={onReplayClick}
                                onToggleDisableStateClick={onToggleDisableStateClick}
                                onToggleMuteStateClick={onToggleMuteStateClick}
                                onApproveClick={onApprove}
                                onDeclineClick={onDecline}
                            />)}

                            {events.map((event) => <EventItem
                                key={event._id}
                                currentUser={currentUser}
                                event={event}
                                onStickerClick={onStickerClick}
                                onReplayClick={onReplayClick}
                                onToggleDisableStateClick={onToggleDisableStateClick}
                                onToggleMuteStateClick={onToggleMuteStateClick}
                                onApproveClick={onApprove}
                                onDeclineClick={onDecline}
                            />)}
                        </>
                        : loading
                            ? <Loader />
                            : <Empty
                                icon={<IconBellSolid/>}
                                iconSize={40}
                                text={t('dashboard.events.emptyText')}
                            />
                }
            </div>
        </ScrollableArea>
    );
};
