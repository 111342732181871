import { EOrientation, ERelativePosition } from '@hyperclap/ui';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';

import { IconChartLine, IconNewspaperLine, IconSmileGrinLine, IconUsersLine } from '@assets/images/svg';
import { CACHED_STATE_INVITE_ACTIVATED_NAME, isNotUndefined, parseBool } from '@common';
import { Menu, MenuItem, MenuItemProps } from '@components';
import { TApp, useInvites } from '@hooks';
import { StreamerList } from '@views';

import { StickersSubmenu } from './components';
import s from './LeftSideMenu.scss';

interface LeftSideMenuProps extends TApp {
    hideMenuActivation?: boolean;
}

const defaultMenuItemProps: MenuItemProps<string> = {
    className: s.leftSideMenuItem,
    iconClassName: s.leftSideMenuItemIcon,
    iconPosition: ERelativePosition.LEFT,
};

export const LeftSideMenu = (props: LeftSideMenuProps) => {
    const {
        navigation: {
            goToPath,
        },
        personalArea: {
            supporters: {
                supportedStreamers,
                setSearchQuery,
            },
        },
        translation: {
            t,
        },
    } = props;

    const {
        inviteActivated,
    } = useInvites();

    const [isDashboardVisible, setIsDashboardVisible] = useState<boolean>(
        // TODO: Make useLocalStorageCache hook to control cached states
        parseBool(localStorage.getItem(CACHED_STATE_INVITE_ACTIVATED_NAME) ?? 'false'),
    );

    const menuStructure: MenuItemProps<string>[] = [
        {
            active: !!useMatch('/dashboard'),
            caption: t('common.dashboard'),
            icon: <IconChartLine/>,
            submenuOpened: true,
            tag: '/dashboard',
            visible: isDashboardVisible,
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/events/*'),
            caption: t('common.events'),
            icon: <IconNewspaperLine/>,
            submenuOpened: true,
            tag: '/events',
            visible: isDashboardVisible,
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/supporters/*'),
            caption: t('common.supporters'),
            icon: <IconUsersLine/>,
            iconClassName: s.favortitesMenuItemIconSizeFix,
            tag: '/supporters',
            visible: isDashboardVisible,
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/stickers'),
            caption: t('common.stickers'),
            icon: <IconSmileGrinLine/>,
            submenu: <StickersSubmenu {...props} />,
            submenuOpened: true,
            tag: '/stickers',
            ...defaultMenuItemProps,
        },
        {
            containerClassName: cn(s.grow, s.streamersListContainer),
            className: s.noMain,
            submenuClassName: s.grow,
            submenu: <StreamerList streamers={supportedStreamers ?? []}/>,
            submenuOpened: true,
            visible: true,
        },
    ];

    const onMenuItemClick = (tag?: string) => {
        if (tag === '/supporters') {
            setSearchQuery('');
        }

        if (tag) goToPath(tag);
    };

    const menuItems = menuStructure.map((itemProps, idx) => (
        <MenuItem key={idx} {...itemProps} onClick={onMenuItemClick}/>
    ));

    useEffect(() => {
        if (isNotUndefined(inviteActivated)) {
            setIsDashboardVisible(inviteActivated ?? false);
        }
    }, [inviteActivated]);

    return (
        <Menu orientation={EOrientation.VERTICAL} className={s.leftSideMenu}>
            {menuItems}
        </Menu>
    );
};
