import { t } from 'i18next';
import React from 'react';

import { IconCheckCircleSolid, IconDenyCircleSolid } from '@assets/images/svg';
import { IModerationStatusEvent, ModerationState } from '@typings';

import { BaseEvent, IEventItemProps } from './EventBase';
import s from './EventBase.scss';

interface IModerationStatusProps extends IEventItemProps<IModerationStatusEvent> {}

export const EventModerationStatus = ({ event, animated }: IModerationStatusProps) => {
    const isApproved = event.status === ModerationState.APPROVED;

    const action = isApproved
        ? t('events.approved')
        : event.status === ModerationState.DELETED
            ? t('events.deleted')
            : t('events.rejected');

    const message = [ModerationState.DECLINED, ModerationState.DELETED].includes(event.status)
        ? event.reason
        : isApproved ? t('events.you-can-send') : '';

    return (
        <BaseEvent
            animated={animated}
            event={event}
            color={s.gray}
            icon={isApproved ? <IconCheckCircleSolid /> : <IconDenyCircleSolid />}
            user={t('events.your-sticker')}
            action={action}
            subject={event.stickerName ?? t('events.without-name')}
            message={message}
        />
    );
};
