import { Button, Column, Row, ERowAlignment, Selector } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import { IconWindowsLine } from '@assets/images/svg';
import { roubleValueText, supporterValueText } from '@common';
import { TApp } from '@hooks';
import { useRawEvents } from '@hooks/app/events';
import { EventsFilterPreset, Period } from '@typings';
import { FilterButton, EStickerPopupControlsMode, useStickerPopup } from '@views';

import s from './Desktop.scss';
import { EventsList, StatisticChart, StatisticTable } from '../../components';

const I18NEXT_DAYS = 'common.days';

interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        currentUser: {
            currentUser,
        },
        personalArea: {
            dashboard: {
                dashboardData: {
                    periodTotal: {
                        earnings,
                        supportersCount,
                    },
                },
                dashboardEvents,
                newDashboardEvents,
                isDashboardEventsFetching,
                isDashboardEventsUninitialized,
                loadSticker,
                changePeriod,
                changeFilters,
                toggleDisableState,
                toggleMuteState,
                approveOnStream,
                declineOnStream,
            },
        },
        translation: { t, i18n },
    } = props;

    const { showStickerPopup, setStickerPopupItem } = useStickerPopup({
        ...props,
        controlsMode: EStickerPopupControlsMode.STREAMER,
    });

    const onStickerClick = async (stickerId: string) => {
        showStickerPopup();
        const sticker = await loadSticker(stickerId).unwrap();
        setStickerPopupItem(sticker);
    };

    const EVENT_TYPE_FILTERS = [
        { caption: t('common.purchases'), value: EventsFilterPreset.PURCHASES },
        { caption: t('common.bonuses'), value: EventsFilterPreset.BONUSES },
        { caption: t('common.stickers'), value: EventsFilterPreset.STICKERS },
        { caption: t('common.meme-cannon'), value: EventsFilterPreset.MEME_CANNON },
        { caption: t('common.fullscreen'), value: EventsFilterPreset.FULLSCREEN },
        { caption: t('common.moderation'), value: EventsFilterPreset.MODERATION },
    ];

    const PERIODS = [
        { caption: `7 ${t(I18NEXT_DAYS)}`, value: Period.WEEK },
        { caption: `30 ${t(I18NEXT_DAYS)}`, value: Period.MONTH },
        { caption: `90 ${t(I18NEXT_DAYS)}`, value: Period.THREE_MONTH },
    ];

    const {
        replayClick,
    } = useRawEvents();

    const onPeriodChanged = ({ value }: { value: Period }) => changePeriod(value);

    const buildEarningString = () => {
        return i18n.language !== 'en'
            ? `${Math.round(earnings)} ${roubleValueText(Math.round(earnings))}`
            : `$${Math.round(earnings)}`;
    };

    return (
        <Column grow margin={{ top: 14 }}>
            <Row grow columnGap={20} alignment={ERowAlignment.STRETCH}>
                <Column grow rowGap={10}>
                    <Row className={s.header}>
                        <Column>
                            {t('common.statistic-for')}
                        </Column>
                        <Column>
                            <Selector
                                valuesList={PERIODS}
                                defaultSelectionIndex={1}
                                hideListOnSelect
                                margin={{ left: 7 }}
                                className={s.periodSelector}
                                onSelectionChange={onPeriodChanged}
                            />
                        </Column>
                    </Row>
                    <Row columnGap={88}>
                        <Column rowGap={10}>
                            <Row className={s.counterValue}>
                                {buildEarningString()}
                            </Row>
                            <Row className={s.counterDescription}>
                                {t('common.earned')}
                            </Row>
                        </Column>
                        <Column rowGap={10}>
                            <Row className={s.counterValue}>
                                {supportersCount} {supporterValueText(supportersCount)}
                            </Row>
                            <Row className={s.counterDescription}>
                                { supportersCount > 1
                                    ? t('common.active-multiple')
                                    : supportersCount === 0
                                        ? t('common.active')
                                        : t('common.active-single')
                                }
                            </Row>
                        </Column>
                    </Row>
                    <Row height={300}>
                        <StatisticChart {...props}/>
                    </Row>
                    <Row>
                        <StatisticTable {...props}/>
                    </Row>
                </Column>

                <Column width={355} rowGap={20}>
                    <Row columnGap={5} className={s.header}>
                        <Column padding={{ left: 10 }}>
                            {t('dashboard.events.lastEvents')}
                        </Column>
                        <Column grow/>
                        <Column>
                            <FilterButton
                                className={cn(s.button, s.buttonCircle)}
                                dropDownClassName={s.filterMenu}
                                valuesList={EVENT_TYPE_FILTERS}
                                showLoader={isDashboardEventsFetching}
                                onSelectionChange={(val) => changeFilters(val.map((v) => v.value))}
                            />
                        </Column>
                        <Column>
                            <Button
                                icon={<IconWindowsLine/>}
                                className={cn(s.button, s.buttonCircle)}
                                onClick={() => window.open('/all-events', '_blank')}
                            />
                        </Column>
                    </Row>
                    <EventsList
                        loading={isDashboardEventsFetching || isDashboardEventsUninitialized}
                        events={dashboardEvents}
                        newEvents={newDashboardEvents}
                        currentUser={currentUser}
                        onStickerClick={onStickerClick}
                        onToggleDisableStateClick={toggleDisableState}
                        onReplayClick={replayClick}
                        onToggleMuteStateClick={toggleMuteState}
                        onApprove={approveOnStream}
                        onDecline={declineOnStream}
                    />
                </Column>
            </Row>
        </Column>
    );
};
