import { Column, EColumnAlignment, EComponentColor, EComponentSize, Label, useLogger } from '@hyperclap/ui';
import React, { useEffect, useRef, useState } from 'react';

import { IPopupMemeCannon, IPopupNickName, useObsStickers } from '@hooks';
import { IPlayingSticker } from '@hooks/app/obs/PlayingStickersList';
import { useSystemSettings } from '@hooks/app/settings/SystemSettings';
import {
    EmptyPage,
    MemeParty,
    PopupMemeCannon,
    PopupNewSale,
    PopupNewSupporter,
    PopupNickName,
    StickerPlayer,
    WelcomeBonusEarned,
} from '@views';

import { Confetti, SettingsControl, WidgetsOverlay } from './components';
import s from './ObsPage.scss';

const VOLUME_FADE_DURATION_PARAMETER = 'obs.voicing.volumeFadeDurationMs';

export const ObsPage = () => {
    const logger = useLogger({ target: ObsPage.name, showTimestamp: true });
    const {
        currentUser,
        unauthorized,
        list,
        fullscreenList,
        memeCannonList,
        size,
        isWidgetsShown,
        isOverlayShown,
        isCornersShown,
        isGridShown,
        isStickingZoneShown,
        memePartyState,
        popupInfo,
        alertTypes,
        removeFromFullscreenList,
        removeFromMemeCannonList,
        removePostedSticker,
    } = useObsStickers();

    const settings = useSystemSettings({ active: !!currentUser });

    const authorized = !unauthorized;

    const widgetsOverlayRef = useRef<HTMLDivElement>(null);
    const widgetContainerRef = useRef<HTMLDivElement>(null);
    const popupNickNameWidgetContainerRef = useRef<HTMLDivElement>(null);
    const popupNewSaleWidgetContainerRef = useRef<HTMLDivElement>(null);
    const popupNewSupporterWidgetContainerRef = useRef<HTMLDivElement>(null);
    const audioRef = useRef<HTMLAudioElement>(null);

    const [memePartyPositionStyles, setMemePartyPositionStyles] = useState({});
    const [popupAlertsPositionStyles, setPopupAlertsPositionStyles] = useState({});
    const [soundPlayed, setSoundPlayed] = useState({});

    const [activeFullscreenSticker, setActiveFullscreenSticker] = useState<IPlayingSticker>();
    const [memePartySound, setMemePartySound] = useState('');

    const [voiceSpeaking, setVoiceSpeaking] = useState(false);

    const removeFullscreenPostedSticker = (uid: string) => {
        if (activeFullscreenSticker) {
            setActiveFullscreenSticker(undefined);
        }

        removeFromFullscreenList(uid);
    };

    const calculatePopupAlertsStyle = () => {
        const style = {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'start', // Y
            justifyContent: 'start', // X
        };

        if (currentUser?.channel.popupNickNameAreaMask) {
            let zoneIndex = 0;

            for (let i = 0; i <= 8; i++) {
                if ((Math.pow(2, i) & currentUser?.channel.popupNickNameAreaMask) > 0) {
                    zoneIndex = i;
                    break;
                }
            }

            switch (zoneIndex) {
                case 1:
                case 7:
                    style.justifyContent = 'center';
                    break;
                case 2:
                case 8:
                    style.justifyContent = 'end';
                    break;
            }

            switch (zoneIndex) {
                case 6:
                case 7:
                case 8:
                    style.alignItems = 'end';
                    break;
            }
        }

        return style;
    };

    useEffect(() => {
        if (!activeFullscreenSticker && fullscreenList.length > 0) {
            setActiveFullscreenSticker(fullscreenList[0]);
        }
    }, [fullscreenList]);

    useEffect(() => {
        if (currentUser) {
            setMemePartySound(
                currentUser.channel?.memePartyOwnSoundEnabled && currentUser.channel?.memePartyOwnSoundUrl
                    ? currentUser.channel?.memePartyOwnSoundUrl
                    : '/assets/sounds/meme-party.mp3',
            );
        }

        if (currentUser && widgetsOverlayRef.current && widgetContainerRef.current) {
            logger.debug(currentUser?.channel?.memePartyProgressPosition);

            const styles = currentUser?.channel?.memePartyProgressPosition &&
                currentUser?.channel?.memePartyProgressPosition?.x &&
                currentUser?.channel?.memePartyProgressPosition?.y
                ? {
                    // eslint-disable-next-line max-len
                    left: (widgetsOverlayRef.current.clientWidth * currentUser?.channel?.memePartyProgressPosition.x) -
                            (widgetContainerRef.current.clientWidth / 2) + 'px',
                    // eslint-disable-next-line max-len
                    top: (widgetsOverlayRef.current.clientHeight * currentUser?.channel?.memePartyProgressPosition.y) -
                            (widgetContainerRef.current.clientHeight / 2) + 'px',
                }
                : {
                    // eslint-disable-next-line max-len
                    // For left X-axis margin is used clientHeight as optimal value when real coordinate in not defined yet
                    left: (widgetContainerRef.current.clientHeight / 2) + 'px',
                    // eslint-disable-next-line max-len
                    top: (widgetContainerRef.current.clientHeight / 2) + 'px',
                };

            logger.debug(styles);

            setMemePartyPositionStyles(styles);
        }

        if (
            currentUser &&
            (
                popupNickNameWidgetContainerRef.current ||
                popupNewSaleWidgetContainerRef.current ||
                popupNewSupporterWidgetContainerRef.current
            )
        ) {
            setPopupAlertsPositionStyles(calculatePopupAlertsStyle());
        }
    }, [currentUser]);

    useEffect(() => {
        if (memePartyState?.active && currentUser?.channel?.memePartySoundEnabled && audioRef.current && !soundPlayed) {
            setSoundPlayed(true);
            audioRef.current.volume = currentUser?.channel?.soundVolume ? currentUser?.channel?.soundVolume / 100 : 0.6;
            void audioRef.current.play();
        }

        if (!memePartyState?.active && audioRef.current) {
            setSoundPlayed(false);
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    }, [memePartyState, currentUser]);

    useEffect(() => {
        logger.debug(voiceSpeaking);
    }, [voiceSpeaking]);

    return (
        <EmptyPage className={s.obsPage}>
            <Column grow className={s.stickersContainer} alignment={EColumnAlignment.CENTER}>
                { unauthorized
                    ? <Label
                        caption={'Ссылка, указывающая на Вашу страницу в memealerts.com, некорректна.\nИспользуйте корректную ссылку!'}
                        size={EComponentSize.LARGE}
                        color={EComponentColor.DANGER}
                        padding={50}
                        style={{ whiteSpace: 'pre', textAlign: 'center', lineHeight: 1.5 }}
                        useBoldFont
                    />
                    : list.map((postedSticker) => (
                        <StickerPlayer
                            key={`${postedSticker.uid}`}
                            uid={postedSticker.uid}
                            sticker={postedSticker.sticker}
                            geometry={postedSticker.geometry}
                            volumeFadeEffectDuration={settings.asInt(VOLUME_FADE_DURATION_PARAMETER)}
                            useAnimation
                            volume={currentUser?.channel?.soundVolume ?? 100}
                            muteVolume={voiceSpeaking}
                            size={size}
                            hidden={postedSticker.hidden}
                            isCopyrightFreeOnly={currentUser?.channel.isCopyrightFreeOnly}
                            onPlayEnd={() => removePostedSticker(postedSticker.uid)}
                        />
                    ))
                }
                { authorized && currentUser?.channel?.memeCannonEnabled && settings.asBool('channel.enableMemeCannon') &&
                    memeCannonList.map((memeCannonSticker) => (
                        <StickerPlayer
                            key={`${memeCannonSticker.uid}`}
                            uid={memeCannonSticker.uid}
                            sticker={memeCannonSticker.sticker}
                            geometry={memeCannonSticker.geometry}
                            volumeFadeEffectDuration={settings.asInt(VOLUME_FADE_DURATION_PARAMETER)}
                            useAnimation
                            volume={currentUser?.channel?.soundVolume ?? 100}
                            muteVolume={voiceSpeaking}
                            size={size}
                            hidden={memeCannonSticker.hidden}
                            isCopyrightFreeOnly={currentUser?.channel.isCopyrightFreeOnly}
                            onPlayEnd={() => removeFromMemeCannonList(memeCannonSticker.uid)}
                        />
                    ))
                }
                { authorized && activeFullscreenSticker &&
                    <StickerPlayer
                        key={`${activeFullscreenSticker.uid}`}
                        uid={activeFullscreenSticker.uid}
                        sticker={activeFullscreenSticker.sticker}
                        geometry={activeFullscreenSticker.geometry}
                        volume={currentUser?.channel?.soundVolume ?? 100}
                        volumeFadeEffectDuration={settings.asInt(VOLUME_FADE_DURATION_PARAMETER)}
                        muteVolume={voiceSpeaking}
                        size={size}
                        fullscreen={true}
                        hidden={activeFullscreenSticker.hidden}
                        pageBackgroundUrl={currentUser?.channel?.backgroundUrl}
                        pageBackgroundMode={currentUser?.channel?.backgroundMode}
                        isCopyrightFreeOnly={currentUser?.channel.isCopyrightFreeOnly}
                        onPlayEnd={() => removeFullscreenPostedSticker(activeFullscreenSticker.uid)}
                    />
                }
                { authorized && isOverlayShown &&
                    <SettingsControl
                        stickerSize={size}
                        showCorners={isCornersShown}
                        showGrid={isGridShown}
                        showStickingZone={isStickingZoneShown}
                    />
                }
                { authorized && isWidgetsShown &&
                    <WidgetsOverlay areaRef={widgetsOverlayRef}>
                        { currentUser?.channel?.memePartyActive &&
                            <div className={s.widgetContainer} style={memePartyPositionStyles} ref={widgetContainerRef}>
                                <audio src={memePartySound} ref={audioRef} />
                                <MemeParty
                                    currencyImageUrl={currentUser?.channel?.currencyImageUrl}
                                    goalValue={memePartyState?.goal ?? 100}
                                    currentValue={memePartyState?.currentValue ?? 0}
                                    obsMode />
                            </div>
                        }
                        { currentUser?.channel?.memePartyActive && currentUser?.channel?.memePartyConfetti && memePartyState?.active &&
                            <Confetti />
                        }
                        { currentUser?.channel?.isPopupNickNameEnabled &&
                            <div className={s.widgetContainer} style={popupAlertsPositionStyles} ref={popupNickNameWidgetContainerRef}>
                                <PopupNickName
                                    popupInfo={popupInfo}
                                    currentUser={currentUser}
                                    voice={(popupInfo as IPopupNickName)?.userVoice}
                                    onVoicePlayStart={() => setVoiceSpeaking(true)}
                                    onVoicePlayEnd={() => setVoiceSpeaking(false)}
                                    alertTypes={alertTypes}
                                />
                            </div>
                        }
                        { currentUser?.channel?.isPopupNickNameEnabled &&
                            <div className={s.widgetContainer} style={popupAlertsPositionStyles} ref={popupNickNameWidgetContainerRef}>
                                <PopupMemeCannon
                                    popupInfo={popupInfo}
                                    voice={(popupInfo as IPopupMemeCannon)?.userVoice}
                                    onVoicePlayStart={() => setVoiceSpeaking(true)}
                                    onVoicePlayEnd={() => setVoiceSpeaking(false)}
                                />
                            </div>
                        }
                        { currentUser?.channel?.isPopupNewSaleEnabled &&
                            <div className={s.widgetContainer} style={popupAlertsPositionStyles} ref={popupNewSaleWidgetContainerRef}>
                                <PopupNewSale
                                    popupInfo={popupInfo}
                                    currentUser={currentUser}
                                    alertTypes={alertTypes}
                                />
                            </div>
                        }
                        { currentUser?.channel?.isNewSupporterAlertEnable &&
                            <div className={s.widgetContainer} style={popupAlertsPositionStyles} ref={popupNewSupporterWidgetContainerRef}>
                                <PopupNewSupporter
                                    popupInfo={popupInfo}
                                    currentUser={currentUser}
                                    alertTypes={alertTypes}
                                />
                            </div>
                        }
                        { currentUser?.channel?.isPopupNewSaleEnabled &&
                            <div className={s.widgetContainer} style={popupAlertsPositionStyles} ref={popupNewSaleWidgetContainerRef}>
                                <WelcomeBonusEarned
                                    popupInfo={popupInfo}
                                    currentUser={currentUser}
                                    alertTypes={alertTypes}
                                />
                            </div>
                        }
                    </WidgetsOverlay>
                }
            </Column>
        </EmptyPage>
    );
};

