import { Button, Column, Row, Slider, useModals } from '@hyperclap/ui';
import cn from 'classnames';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IconMusicLine, IconSoundActiveLine, IconTrashAltLine, IconUploadLine } from '@assets/images/svg';

import { SoundSelector } from './components/SoundSelector';
import s from './Sound.scss';
import { useAlertsSettingsType } from '../../hooks';

interface ISoundProps {
    alertsSettingsType: ReturnType<typeof useAlertsSettingsType>;
}

export const Sound = (props: ISoundProps) => {
    const {
        alertsSettingsType: {
            alertStyle,
            onSoundStickerChange,
            onSoundFileChange,
            onSoundVolumeChange,
        },
    } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.soundSettings.${id}`);
    const { showModal, setContent } = useModals();

    const SoundSelectorContent = (
        <SoundSelector
            value={alertStyle.soundId}
            onChange={onSoundStickerChange}
        />
    );

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSoundFileChange(event.target.files?.[0]);
    };

    const handleSelectSoundClick = () => {
        showModal({
            content: SoundSelectorContent,
            options: {},
        });
    };

    const handleRemoveClick = () => onSoundStickerChange();

    useEffect(
        () => {
            setContent(SoundSelectorContent);
        },
        [
            alertStyle.soundId,
        ],
    );

    return (
        <div className={s.root}>
            <Row className={s.buttons}>
                <Column>
                    <Row>
                        <Button
                            className={cn(s.buttonsItem, s.buttonsItemUpload)}
                            captionClassName={cn(s.buttonsItemCaption, s.buttonsItemUploadCaption)}
                            caption={(
                                <>
                                    <input
                                        type="file"
                                        className={s.buttonsItemUploadFile}
                                        onChange={handleFileChange}
                                    />

                                    <IconUploadLine className={s.buttonsItemIcon}/>
                                </>
                            )}
                        />

                        <Button
                            className={s.buttonsItem}
                            captionClassName={s.buttonsItemCaption}
                            caption={(
                                <IconMusicLine className={s.buttonsItemIcon}/>
                            )}
                            onClick={handleSelectSoundClick}
                        />

                        <Button
                            className={s.buttonsItem}
                            captionClassName={s.buttonsItemCaption}
                            caption={(
                                <IconTrashAltLine className={s.buttonsItemIcon}/>
                            )}
                            onClick={handleRemoveClick}
                        />
                    </Row>
                </Column>

                <Column>
                    {alertStyle.soundName && (
                        <Row className={s.seleceted}>
                            <IconSoundActiveLine className={s.selectedIcon}/>
                            <div className={s.selectedName}>{alertStyle.soundName}</div>
                        </Row>
                    )}
                </Column>
            </Row>

            <Row>
                <Slider
                    caption={t('volume')}
                    className={s.volumeSlider}
                    classNames={{
                        caption: s.volumeSliderCaption,
                        track: s.volumeSliderTrack,
                        trackProgress: s.volumeSliderTrackProgress,
                        thumbGizmo: s.volumeSliderThumbGizmo,
                    }}
                    positionDelimiter=""
                    showPosition
                    minValue={0}
                    maxValue={100}
                    initialValue={alertStyle.soundVolume}
                    realtimeUpdate
                    onCommit={onSoundVolumeChange}
                />

                <Button
                    className={s.volumeMute}
                    captionClassName={s.volumeMuteCaption}
                    caption={(
                        <IconSoundActiveLine className={s.volumeMuteIcon}/>
                    )}
                />
            </Row>
        </div>
    );
};
