import { ETooltipPosition, Loader, Tooltip } from '@hyperclap/ui';
import cn from 'classnames';
import { t } from 'i18next';
import React, { useState } from 'react';

import {
    IconDizzySolid,
    IconDizzyLine,
    IconPoopLine,
    IconPoopSolid,
    IconReplayLine,
    IconCupLine,
} from '@assets/images/svg';
import { isUndefined } from '@common';
import { IMemeCannonStickerSentEvent, IStickerSentEvent } from '@typings';

import s from './Controls.scss';

interface IStickerSentContentProps {
    event: IMemeCannonStickerSentEvent | IStickerSentEvent;
    onShowBonusPopup?: (event: React.MouseEvent) => void;
    onReplayClick(event: IMemeCannonStickerSentEvent | IStickerSentEvent): void;
    onToggleDisableStateClick(event: IMemeCannonStickerSentEvent | IStickerSentEvent): void;
    onToggleMuteStateClick(event: IMemeCannonStickerSentEvent | IStickerSentEvent): void;
}

interface EventButtonProps {
    onClick?: (event: React.MouseEvent) => void;
    icon: React.ReactNode;
    tooltip: string;
    loading?: boolean;
    disabled?: boolean;
}

const Button = ({ onClick, icon, tooltip, loading, disabled }: EventButtonProps) => {
    const className = cn(s.button, { [s.active]: disabled });

    if (loading) {
        return (<div className={className}><Loader size={16} /></div>);
    }

    return (
        <div onClick={onClick} className={className}>
            <Tooltip
                content={tooltip}
                position={ETooltipPosition.TOP}
                classNames={{ content: s.tooltip }}
            >
                {icon}
            </Tooltip>
        </div>
    );
};

export const Controls = (props: IStickerSentContentProps) => {
    const {
        onReplayClick,
        onToggleDisableStateClick,
        onToggleMuteStateClick,
        onShowBonusPopup,
        event,
    } = props;
    const [isReplayInProcess, setIsReplayInProcess] = useState(false);
    const [isBanInProcess, setIsBanInProcess] = useState(false);
    const [isMuteInProcess, setIsMuteInProcess] = useState(false);

    const onReplayButtonClick = () => {
        setIsReplayInProcess(true);

        onReplayClick(event);

        setTimeout(() => {
            setIsReplayInProcess(false);
        }, 1000);
    };

    const onPoopButtonClick = () => {
        setIsBanInProcess(true);

        setTimeout(() => {
            onToggleDisableStateClick(event);
            setIsBanInProcess(false);
        }, 1000);
    };

    const onMuteUserClick = () => {
        setIsMuteInProcess(true);

        setTimeout(() => {
            onToggleMuteStateClick(event);
            setIsMuteInProcess(false);
        }, 1000);
    };

    return (
        <div className={s.controls}>
            <Button
                disabled={event.isStickerDisabled}
                onClick={onShowBonusPopup}
                tooltip={t('supporters.give-bonus')}
                icon={<IconCupLine/>}
            />

            <Button
                loading={isBanInProcess}
                disabled={event.isStickerDisabled}
                onClick={onPoopButtonClick}
                tooltip={event.isStickerDisabled ? t('mute.unblock-sticker') : t('mute.block-sticker')}
                icon={event.isStickerDisabled ? <IconPoopSolid /> : <IconPoopLine/>}
            />
            <Button
                loading={isMuteInProcess}
                disabled={event.isUserMutedByStreamer}
                onClick={onMuteUserClick}
                tooltip={event.isUserMutedByStreamer ? t('mute.unblock-user') : t('mute.block-user')}
                icon={event.isUserMutedByStreamer ? <IconDizzySolid /> : <IconDizzyLine/>}
            />
            {(isUndefined(event.preModerationResult) || event.preModerationResult) &&
                <Button
                    loading={isReplayInProcess}
                    onClick={onReplayButtonClick}
                    tooltip={t('common.replay')}
                    icon={<IconReplayLine/>}
                />
            }
        </div>
    );
};
