import { EComponentSize, Loader, useClickOutside } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import s from './FilterButton.scss';
import { IconFilterLine } from './images';


type TCaptioned = string | { caption: string };

interface IFilterButtonProps<T> {
    className?: string;
    dropDownClassName?: string;
    valuesList: Array<T>;
    initialSelection?: Array<number>;
    singleValueSelectionMode?: boolean;
    showLoader?: boolean;
    onSelectionChange?: (selection: Array<T>, indexes: Array<number>) => void;
}

export const FilterButton = <T extends TCaptioned>(props: IFilterButtonProps<T>) => {
    const {
        className,
        dropDownClassName,
        valuesList,
        initialSelection,
        singleValueSelectionMode,
        showLoader,
        onSelectionChange = noop,
    } = props;

    const listRef = useRef<HTMLDivElement>(null);

    useClickOutside({
        controlledElementRef: listRef,
        handler: () => setIsListVisible(false),
    });

    const [isListVisible, setIsListVisible] = useState(false);
    const [selectedValues, setSelectedValues] = useState<Array<T>>(initialSelection
        ? valuesList.filter((_, idx) => initialSelection?.includes(idx))
        : valuesList,
    );
    const [selectedValuesIndexes, setSelectedValuesIndexes] = useState<Array<number>>(initialSelection ?? valuesList.map((_, idx) => idx));

    const onItemClick = (item: T, idx: number, e: React.MouseEvent) => {
        e.stopPropagation();

        const alreadySelected = selectedValuesIndexes.includes(idx);

        if (singleValueSelectionMode) {
            if (!alreadySelected) {
                setSelectedValues([valuesList[idx]]);
                setSelectedValuesIndexes([idx]);
            }

            return;
        }

        if (alreadySelected) {
            setSelectedValues(selectedValues.filter((value) => {
                const valueCaption = typeof value === 'string' ? value : value.caption;
                const itemCaption = typeof item === 'string' ? item : item.caption;

                return valueCaption !== itemCaption;
            }));
            setSelectedValuesIndexes(selectedValuesIndexes.filter((i) => i !== idx));
        } else {
            setSelectedValues(selectedValues.concat([item]));
            setSelectedValuesIndexes(selectedValuesIndexes.concat([idx]));
        }
    };

    const items = valuesList.map((item, idx) => {
        const caption = typeof item === 'string' ? item : item.caption;

        return (
            <div
                key={idx}
                className={cn(
                    s.filterMenuItem,
                    { [s.filterMenuItemActive]: selectedValuesIndexes.includes(idx) },
                )}
                onClick={(e) => onItemClick(item, idx, e)}
            >
                <div className={s.filterMenuItemCheck}/>
                <div>
                    {caption}
                </div>
            </div>
        );
    });

    const onButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsListVisible((prev) => !prev);
    };

    useEffect(() => {
        onSelectionChange(selectedValues, selectedValuesIndexes);
    }, [selectedValues]);

    return (
        <div className={cn(s.filterButton, className)} onClick={onButtonClick} ref={listRef}>
            <div className={s.filterButtonIcon}>
                <IconFilterLine />
            </div>

            <div
                className={cn(
                    s.filterMenu,
                    { [s.filterMenuVisible]: isListVisible },
                    dropDownClassName,
                )}
            >
                {items}

                {showLoader &&
                    <div className={s.loaderCover} onClick={(e) => e.stopPropagation()}>
                        <Loader size={EComponentSize.MEDIUM} />
                    </div>
                }
            </div>
        </div>
    );
};
