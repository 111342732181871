import { Button, Column, ERowJustification, Paginator, Row } from '@hyperclap/ui';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IconDizzyLine, IconCupLine } from '@assets/images/svg';
import { DATE_FORMAT_LONG, declensionUserCurrency } from '@common';
import { NotificationType } from '@contexts';
import { TApp, useNotifications } from '@hooks';
import { useSupporter } from '@hooks/app/personal-area/Supporter';

import s from './Desktop.scss';
import { BonusPopup, EventsTable } from '../../components';


interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const { notify } = useNotifications();
    const { supporterId } = useParams();
    const {
        currentUser: {
            currentUser,
        },
        mute: {
            muteUser,
            unmuteUser,
        },
        translation: {
            i18n,
            t,
        },
        navigation: {
            goToSupporters,
        },
    } = props;

    const {
        bonusSize,
        events,
        pagesCount,
        supporter,
        supporterNotFound,
        changePage,
        sendBonus,
        setBonusSize,
    } = useSupporter({ supporterId: supporterId ?? '' });

    const dataReady = currentUser && events;

    const [joinDate, setJoinDate] = useState('');
    const [balance, setBalance] = useState(0);
    const [isBonusPopupShown, setIsBonusPopupShown] = useState(false);

    useEffect(() => {
        if (supporter) {
            setJoinDate(new Date(supporter.joined).toLocaleString(i18n.language, DATE_FORMAT_LONG));
            setBalance(supporter.balance);
        }
    }, [supporter]);

    useEffect(() => {
        if (supporterNotFound) goToSupporters();
    }, [supporterNotFound]);

    const onBonusButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (isBonusPopupShown) {
            setIsBonusPopupShown(false);
        } else if (currentUser) {
            setIsBonusPopupShown(true);
        }
    };

    const onSendBonusClick = async () => {
        sendBonus(supporter!.supporterId, currentUser!.id);
        setBalance((prev) => prev + bonusSize);
        setIsBonusPopupShown(false);
        notify('Награда успешно отправлена!', NotificationType.SUCCESS); // TODO: translation
    };

    const onMuteSwitchClick = async () => {
        if (supporter) {
            supporter.mutedByStreamer
                ? await unmuteUser(supporter.supporterId)
                : await muteUser(supporter.supporterId);
            notify(`Пользователь ${supporter.mutedByStreamer
                ? 'разблокирован'
                : 'заблокирован'
            }!`, NotificationType.SUCCESS); // TODO: translation
        }
    };

    return (
        <Column grow margin={{ top: 25 }} rowGap={24}>
            <Row className={s.header}>
                <Column rowGap={10}>
                    <Row>
                        {supporter?.supporterName}
                    </Row>
                    <Row className={s.counterDescription}>
                        {t('supporters.joined')}: {joinDate}
                    </Row>
                </Column>
                <Column grow/>
                <Column>
                    <Row columnGap={10} justification={ERowJustification.END}>
                        <Button
                            caption={supporter?.mutedByStreamer ? t('common.unblock') : t('common.block')}
                            className={s.button}
                            icon={<IconDizzyLine/>}
                            onClick={onMuteSwitchClick}
                        />
                        <Button
                            caption={t('supporters.give-bonus')}
                            className={s.button}
                            icon={<IconCupLine/>}
                            onClick={onBonusButtonClick}
                        />
                    </Row>
                </Column>
                <Column className={s.bonusPopupContainer}>
                    {!!currentUser && isBonusPopupShown &&
                        <BonusPopup
                            price={currentUser.channel.catalogueStickerPrice}
                            onBonusSizeChange={setBonusSize}
                            onClose={() => setIsBonusPopupShown((prev) => !prev)}
                            onSendBonusClick={onSendBonusClick}
                        />
                    }
                </Column>
            </Row>

            <Row>
                <Column rowGap={10} margin={{ right: 88 }}>
                    <Row className={s.counterValue} columnGap={5}>
                        <Column>
                            {Math.round(balance ?? 0)}
                        </Column>
                        <Column>
                            {declensionUserCurrency(Math.round(balance ?? 0), currentUser)}
                        </Column>
                    </Row>
                    <Row className={s.counterDescription}>
                        {t('supporters.balance')}
                    </Row>
                </Column>
                <Column rowGap={5}>
                    <Row className={s.counterValue} columnGap={5}>
                        <Column>
                            {Math.round(supporter?.spent ?? 0)}
                        </Column>
                        <Column>
                            {declensionUserCurrency(Math.round(supporter?.spent ?? 0), currentUser)}
                        </Column>
                    </Row>
                    <Row className={s.counterDescription}>
                        {t('supporters.total-spent')}
                    </Row>
                </Column>
                <Column grow/>
                {pagesCount > 1 &&
                    <Column>
                        <Paginator
                            page={1}
                            pagesCount={pagesCount}
                            maxDisplayedPages={7}
                            showNavigation
                            onChangePage={changePage}
                        />
                    </Column>
                }
            </Row>
            <Row>
                {dataReady && <EventsTable data={events} currentUser={currentUser}/>}
            </Row>
        </Column>
    );
};
