import { useLogger } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React, { useEffect, useRef } from 'react';

import { EPopupTypes, IPopupInfo, IPopupNickName, useSettings } from '@hooks';
import {
    AlertType,
    IAlertTypeItem,
    IUser,
    IVoiceDescriptor,
    TAlertsList,
} from '@typings';
import { Alert } from '@views/fragments/Alert';
import { useVoices } from '@views/pages/PersonalSettings/components/AvatarEditor/hooks';
import { DEFAULT_ALERT_STYLE } from '@views/pages/StreamerSettings/components/AlertSettings/const';

const VOICING_ENABLED_GLOBALLY = 'obs.voicing.enabled';

interface PopupNickNameProps {
    popupInfo?: IPopupInfo;
    currentUser: IUser;
    alertTypes: TAlertsList;
    voice?: Pick<IVoiceDescriptor, 'voice' | 'provider'>;
    onVoicePlayStart?: () => void;
    onVoicePlayEnd?: () => void;
}

export const PopupNickName = (props: PopupNickNameProps) => {
    const {
        currentUser,
        popupInfo,
        voice,
        alertTypes,
        onVoicePlayStart = noop,
        onVoicePlayEnd = noop,
    } = props;

    const logger = useLogger({ target: PopupNickName.name, showTimestamp: true });
    const { convertVoice, isVoicingEnabledOnChannel } = useVoices();
    const { system } = useSettings();

    const voicePlayer = useRef<HTMLAudioElement>(null);
    const soundPlayer = useRef<HTMLAudioElement>(null);

    const info = popupInfo as IPopupNickName;
    const stickerAlertType = alertTypes.find((item) => item.type === AlertType.STICKER) as IAlertTypeItem;
    const alert = info?.alert as IAlertTypeItem || stickerAlertType || DEFAULT_ALERT_STYLE;

    const variables = {
        name: currentUser?.name,
        sticker: info?.stickerName,
        text: info?.message,
    };

    const playSound = async () => {
        return new Promise<void>((resolve) => {
            const player = soundPlayer.current;

            if (player && alert.soundUrl) {
                player.src = alert.soundUrl;

                if (alert.soundVolume) {
                    player.volume = alert.soundVolume / 100;
                }

                const onEnded = () => {
                    resolve();
                    player.removeEventListener('ended', onEnded);
                };

                const onError = () => {
                    resolve();
                    player.removeEventListener('error', onError);
                };

                player.addEventListener('ended', onEnded);
                player.addEventListener('error', onError);

                player.play();
            } else {
                resolve();
            }
        });
    };

    const playVoice = () => {
        if (system.asBool(VOICING_ENABLED_GLOBALLY) && isVoicingEnabledOnChannel && voice && info?.message) {
            onVoicePlayStart();
            convertVoice({
                voice: voice.voice,
                provider: voice.provider,
                id: '',
                name: '',
                parameters: {},
            }, info.message, voicePlayer);
        }
    };

    const playSounds = async () => {
        try {
            await playSound();
        } catch (err) {
            logger.error(err);
        }

        if (info?.message) {
            playVoice();
        }
    };

    const onVoicePlayed = () => {
        onVoicePlayEnd();
    };

    useEffect(() => {
        if (info?.type === EPopupTypes.NICK_NAME) {
            void playSounds();
        }
    }, [info]);

    return info?.type === EPopupTypes.NICK_NAME
        ? (
            <>
                <Alert
                    alert={alert}
                    imageUrl={info.userAvatar as string || currentUser.avatar}
                    titleVariables={variables as unknown as Record<string, string>}
                    textVariables={variables as unknown as Record<string, string>}
                />

                <audio onEnded={onVoicePlayed} ref={voicePlayer}/>
                <audio ref={soundPlayer}/>
            </>
        )
        : null;
};
