import {
    Column,
    ETooltipPosition,
    ISelectOption,
    ITextFieldMethods,
    Row,
    Select,
    TextField,
    Tooltip,
    useModals,
} from '@hyperclap/ui';
import { delay, getFromLocalStorage, getHumanReadableNumber, setToLocalStorage } from '@hyperclap/utils';
import cn from 'classnames';
import React, { CSSProperties, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ImageDefaultCurrency } from '@assets/images/png';
import {
    IconBomb,
    IconDesktopLine,
    IconHeartLine,
    IconHeartSolid,
    IconSendMessageLine,
    IconSoundActiveLine,
} from '@assets/images/svg';
import { Avatar } from '@components';
import { useAppActions } from '@hooks';
import { ISticker } from '@typings';
import { BuyCurrencyPopup, Login } from '@views';
import { IconMemeCannon, IconSound } from '@views/modals/StickerPopup/components/components/ControlsSend/assets';

import { VoiceTooltip } from './components/VoiceTooltip';
import s from './ControlsSend.scss';
import { useControlsSend } from './hooks';
import { IStickerPopupDataExtended } from '../../../types';
import { Timer } from '../Timer';

enum EButton {
    SEND,
    FULLSCREEN,
    CANNON,
    FAVORITES,
}

enum EButtonState {
    DEFAULT,
    SENDING,
    SENT,
    COOLDOWN,
}

const SEND_ANIMATION_DURATION = {
    [EButtonState.SENDING]: 1000,
    [EButtonState.SENT]: 1000,
    [EButtonState.COOLDOWN]: 3000,
};

const MAX_MESSAGE_LENGTH = 50;

interface IControlsSendProps extends IStickerPopupDataExtended {
    sticker: ISticker;
    updateSticker(stickerData: ISticker): void;
}

export const ControlsSend = (props: IControlsSendProps) => {
    const {
        streamer: { streamer },
        currentUser: { currentUser },
        deviceInfo: {
            isMobileLayoutRecommended,
            isAppleMobileDevice,
        },
        sticker,
    } = props;
    const [isVoiceTooltipHidden, setIsVoiceTooltipHidden] = useState(getFromLocalStorage('stickerPopupVoiceTooltip') === '1');

    const showBuyCurrencyPopup = () => {
        if (streamer && currentUser) {
            showModal({
                content: (
                    <BuyCurrencyPopup
                        streamer={streamer}
                        currentUser={currentUser}
                        isMobileLayoutRecommended={isMobileLayoutRecommended}
                    />
                ),
                options: {
                    bodyClassName: cn(
                        s.buyCurrencyPopupBody,
                        {
                            [s.buyCurrencyPopupBodyMobileLayout]: isMobileLayoutRecommended,
                        },
                    ),
                    containerClassName: cn(
                        s.buyCurrencyPopupContainer,
                        {
                            [s.buyCurrencyPopupContainerMobileLayout]: isMobileLayoutRecommended,
                            [s.buyCurrencyPopupContainerMobileLayoutApple]: isAppleMobileDevice,
                        },
                    ),
                },
            });
        }
    };

    const showAuth = () => {
        showModal({
            content: <Login {...props}/>,
            options: {
                bodyClassName: s.loginModalBody,
            },
        });
    };

    const {
        memeCannonStickersCount,
        price,
        cooldownTime,
        isMessageEnabled,
        isSoundOnly,
        isCannonCountDropdownOpened,
        onStickerSend,
        onFullscreenSend,
        onMessageChange,
        onMemeCannonStickersCountChange,
        onCannonSend,
        onFavoritesClick,
        onSoundOnlyChange,
        onCannonCountClick,
        onCannonCountOutsideClick,
    } = useControlsSend({
        ...props,
        showBuyCurrencyPopup,
        showAuth,
    });
    const { showModal } = useModals();
    const { setSendButtonAnimationActive } = useAppActions();
    const [buttonState, setButtonState] = useState({
        [EButton.SEND]: EButtonState.DEFAULT,
        [EButton.FULLSCREEN]: EButtonState.DEFAULT,
        [EButton.CANNON]: EButtonState.DEFAULT,
    });
    const progressBgCooldownStyles: CSSProperties = { animationDuration: `${Math.round(cooldownTime / 1000)}s` };

    const messageInputMethodsRef = useRef<ITextFieldMethods>(null);
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.controlsSend.${id}`);

    const BUTTON_TEXTS = {
        [EButton.SEND]: {
            [EButtonState.DEFAULT]: t('send'),
            [EButtonState.SENDING]: t('sending'),
            [EButtonState.SENT]: t('sent'),
            [EButtonState.COOLDOWN]: t('cooldown'),
        },
        [EButton.FULLSCREEN]: {
            [EButtonState.DEFAULT]: t('fullscreen'),
            [EButtonState.SENDING]: t('sending'),
            [EButtonState.SENT]: t('sent'),
            [EButtonState.COOLDOWN]: t('cooldown'),
        },
        [EButton.CANNON]: {
            [EButtonState.DEFAULT]: t('cannon'),
            [EButtonState.SENDING]: t('sending'),
            [EButtonState.SENT]: t('sent'),
            [EButtonState.COOLDOWN]: t('cooldown'),
        },
    };

    const currencyImageUrl = streamer?.channel?.currencyImageUrl || ImageDefaultCurrency;

    const setButtonItemState = (button: EButton, state: EButtonState) => {
        setButtonState({
            ...buttonState,
            [button]: state,
        });
    };

    const runButtonAnimation = async (button: EButton) => {
        setButtonItemState(button, EButtonState.SENDING);
        await delay(SEND_ANIMATION_DURATION[EButtonState.SENDING]);
        setButtonItemState(button, EButtonState.SENT);
        await delay(SEND_ANIMATION_DURATION[EButtonState.SENT]);
        setButtonItemState(button, EButtonState.COOLDOWN);
        await delay(cooldownTime);
        setButtonItemState(button, EButtonState.DEFAULT);
    };

    const handleSendClick = async () => {
        if (buttonState[EButton.SEND] === EButtonState.DEFAULT) {
            await onStickerSend();
            await runButtonAnimation(EButton.SEND);
            setSendButtonAnimationActive(false);

            messageInputMethodsRef.current?.reset();
        }
    };

    const handleFullscreenClick = async () => {
        if (buttonState[EButton.FULLSCREEN] === EButtonState.DEFAULT) {
            await onFullscreenSend();
            await runButtonAnimation(EButton.FULLSCREEN);
            setSendButtonAnimationActive(false);
        }
    };

    const handleCannonClick = async () => {
        if (buttonState[EButton.CANNON] === EButtonState.DEFAULT) {
            await onCannonSend();
            await runButtonAnimation(EButton.CANNON);
            setSendButtonAnimationActive(false);
        }
    };

    const handleMemeCannonStickersCountChange = (selection?: ISelectOption) => {
        if (selection) {
            onMemeCannonStickersCountChange(Number(selection.value));
        }
    };

    const handleSoundOnlyChange = () => onSoundOnlyChange(!isSoundOnly);

    const handleMessageKeyDown = (event: React.KeyboardEvent) => {
        event.stopPropagation();
    };

    const handleGoToVoiceSettings = () => {
        setToLocalStorage('stickerPopupVoiceTooltip', '1');
        setIsVoiceTooltipHidden(true);

        window.open('/settings');
    };

    return (
        <div className={s.root}>
            {streamer?.channel.disableStickers
                ? (
                    <Column
                        className={s.disabled}
                    >
                        <div className={s.disabledText}>{t('disabled')}</div>

                        <div
                            className={
                                cn(
                                    s.button,
                                    s.buttonFavorites,
                                    s.buttonActive,
                                    s.disabledButton,
                                )
                            }
                            onClick={onFavoritesClick}
                        >
                            <div className={s.buttonContent}>
                                {sticker.isFavorite
                                    ? (
                                        <IconHeartSolid className={cn(s.buttonIcon, s.buttonIconActive)}/>
                                    )
                                    : (
                                        <IconHeartLine className={s.buttonIcon}/>
                                    )
                                }

                                <div className={s.buttonText}>
                                    {sticker.likedCount ? sticker.likedCount : t('favorites')}
                                </div>
                            </div>
                        </div>
                    </Column>
                )
                : (
                    <>
                        {isMessageEnabled && (
                            <Row className={cn(s.row, s.rowMessage)}>
                                {!isVoiceTooltipHidden && <VoiceTooltip onClick={handleGoToVoiceSettings}/>}

                                <Tooltip
                                    content={t('streamojiSettings')}
                                    position={ETooltipPosition.TOP}
                                >
                                    <Avatar
                                        className={s.messageAvatar}
                                        source={currentUser?.avatar}
                                        isUserAvatar={true}
                                        rounded={true}
                                        onClick={handleGoToVoiceSettings}
                                    />
                                </Tooltip>

                                <TextField
                                    wrapperClassName={s.message}
                                    placeholder={t('messagePlaceholder')}
                                    onChanged={onMessageChange}
                                    onKeyDown={handleMessageKeyDown}
                                    maxLength={MAX_MESSAGE_LENGTH}
                                    methodsRef={messageInputMethodsRef}
                                />

                                {/*
                                <div className={s.buttonAdditionalIconWrapper}>
                                    <Tooltip
                                        content={t('messageVoice.on')}
                                        position={ETooltipPosition.TOP}
                                        classNames={{
                                            root: s.buttonAdditionalIconTooltip,
                                            content: s.tooltipContent,
                                        }}
                                    >
                                        <IconMicrophoneLine
                                            className={s.buttonAdditionalIcon}
                                        />
                                    </Tooltip>
                                </div>
                                */}
                            </Row>
                        )}

                        <Column>
                            <Row className={s.buttonWrapper}>
                                <div
                                    className={
                                        cn(
                                            s.button,
                                            s.buttonSend,
                                            s.buttonActive,
                                            {
                                                [s.buttonSending]: buttonState[EButton.SEND] === EButtonState.SENDING,
                                                [s.buttonSent]: buttonState[EButton.SEND] === EButtonState.SENT,
                                                [s.buttonCooldown]: buttonState[EButton.SEND] === EButtonState.COOLDOWN,
                                            },
                                        )
                                    }
                                    onClick={handleSendClick}
                                >
                                    <div className={s.buttonContent}>
                                        <IconSendMessageLine className={s.buttonIcon}/>

                                        <div className={s.buttonText}>{BUTTON_TEXTS[EButton.SEND][buttonState[EButton.SEND]]}</div>
                                    </div>

                                    {buttonState[EButton.SEND] === EButtonState.DEFAULT && (
                                        <div className={s.buttonPrice}>
                                            <div
                                                className={s.buttonPriceIcon}
                                                style={{ backgroundImage: `url("${currencyImageUrl}")` }}
                                            />
                                            <div className={s.buttonPriceValue}>{price.sticker}</div>
                                        </div>
                                    )}

                                    <div
                                        className={s.buttonProgressBg}
                                        style={buttonState[EButton.SEND] === EButtonState.COOLDOWN ? progressBgCooldownStyles : {}}
                                    />

                                    {buttonState[EButton.SEND] === EButtonState.COOLDOWN && (
                                        <Timer
                                            className={s.buttonTimer}
                                            time={cooldownTime}
                                        />
                                    )}
                                </div>

                                <div className={s.buttonAdditionalIconWrapper}>
                                    <Tooltip
                                        content={isSoundOnly ? t('soundOnly.off') : t('soundOnly.on')}
                                        position={ETooltipPosition.TOP}
                                        classNames={{
                                            root: s.buttonAdditionalIconTooltip,
                                            content: s.tooltipContent,
                                        }}
                                    >
                                        {isSoundOnly
                                            ? (
                                                <IconSoundActiveLine
                                                    className={s.buttonAdditionalIcon}
                                                    onClick={handleSoundOnlyChange}
                                                />
                                            )
                                            : (
                                                <IconSound
                                                    className={s.buttonAdditionalIcon}
                                                    onClick={handleSoundOnlyChange}
                                                />
                                            )
                                        }
                                    </Tooltip>
                                </div>
                            </Row>

                            <Row className={s.buttonWrapper}>
                                <Tooltip
                                    content={t('fullscreenTooltip')}
                                    position={ETooltipPosition.TOP}
                                    classNames={{
                                        content: s.tooltipContent,
                                    }}
                                >
                                    <div
                                        className={
                                            cn(
                                                s.button,
                                                s.buttonFullscreen,
                                                s.buttonActive,
                                                {
                                                    [s.buttonSending]: buttonState[EButton.FULLSCREEN] === EButtonState.SENDING,
                                                    [s.buttonSent]: buttonState[EButton.FULLSCREEN] === EButtonState.SENT,
                                                    [s.buttonCooldown]: buttonState[EButton.FULLSCREEN] === EButtonState.COOLDOWN,
                                                },
                                            )
                                        }
                                        onClick={handleFullscreenClick}
                                    >
                                        <div className={s.buttonContent}>
                                            <IconDesktopLine className={s.buttonIcon}/>

                                            <div className={s.buttonText}>
                                                {BUTTON_TEXTS[EButton.FULLSCREEN][buttonState[EButton.FULLSCREEN]]}
                                            </div>
                                        </div>

                                        {buttonState[EButton.FULLSCREEN] === EButtonState.DEFAULT && (
                                            <div className={s.buttonPrice}>
                                                <div
                                                    className={s.buttonPriceIcon}
                                                    style={{ backgroundImage: `url("${currencyImageUrl}")` }}
                                                />
                                                <div className={s.buttonPriceValue}>{price.fullscreen}</div>
                                            </div>
                                        )}

                                        <div
                                            className={s.buttonProgressBg}
                                            style={buttonState[EButton.FULLSCREEN] === EButtonState.COOLDOWN ? progressBgCooldownStyles : {}}
                                        />

                                        {buttonState[EButton.FULLSCREEN] === EButtonState.COOLDOWN && (
                                            <Timer
                                                className={s.buttonTimer}
                                                time={cooldownTime}
                                            />
                                        )}
                                    </div>
                                </Tooltip>
                            </Row>

                            <Row className={s.buttonWrapper}>
                                <Tooltip
                                    content={t('cannonTooltip').replace('%count%', String(memeCannonStickersCount))}
                                    position={ETooltipPosition.TOP}
                                    classNames={{
                                        content: s.tooltipContent,
                                    }}
                                >
                                    <div
                                        className={
                                            cn(
                                                s.button,
                                                s.buttonCannon,
                                                s.buttonActive,
                                                {
                                                    [s.buttonSending]: buttonState[EButton.CANNON] === EButtonState.SENDING,
                                                    [s.buttonSent]: buttonState[EButton.CANNON] === EButtonState.SENT,
                                                    [s.buttonCooldown]: buttonState[EButton.CANNON] === EButtonState.COOLDOWN,
                                                },
                                            )
                                        }
                                        onClick={handleCannonClick}
                                    >
                                        <div className={s.buttonContent}>
                                            <IconBomb className={s.buttonIcon}/>

                                            <div className={s.buttonText}>{BUTTON_TEXTS[EButton.CANNON][buttonState[EButton.CANNON]]}</div>
                                        </div>

                                        {buttonState[EButton.CANNON] === EButtonState.DEFAULT && (
                                            <div className={s.buttonPrice}>
                                                <div
                                                    className={s.buttonPriceIcon}
                                                    style={{ backgroundImage: `url("${currencyImageUrl}")` }}
                                                />
                                                <div className={s.buttonPriceValue}>{price.cannon}</div>
                                            </div>
                                        )}

                                        <div
                                            className={s.buttonProgressBg}
                                            style={buttonState[EButton.CANNON] === EButtonState.COOLDOWN ? progressBgCooldownStyles : {}}
                                        />

                                        {buttonState[EButton.CANNON] === EButtonState.COOLDOWN && (
                                            <Timer
                                                className={s.buttonTimer}
                                                time={cooldownTime}
                                            />
                                        )}
                                    </div>
                                </Tooltip>

                                <div className={s.buttonAdditionalIconWrapper}>
                                    <Tooltip
                                        content={t('stickersCount')}
                                        position={ETooltipPosition.TOP}
                                        classNames={{
                                            root: s.buttonAdditionalIconTooltip,
                                            content: s.tooltipContent,
                                        }}
                                    >
                                        <IconMemeCannon
                                            className={cn(s.buttonAdditionalIcon, s.buttonAdditionalIconMemeCannon)}
                                            onClick={onCannonCountClick}
                                        />

                                        <div
                                            className={cn(
                                                s.buttonAdditionalIconContent,
                                                s[`buttonAdditionalIconContent-${memeCannonStickersCount}`],
                                            )}
                                        >{memeCannonStickersCount}</div>
                                    </Tooltip>

                                    <Select
                                        classNameDropdown={cn(s.cannonCount, { [s.cannonCountVisisble]: isCannonCountDropdownOpened })}
                                        isOnlyDropdown={true}
                                        options={[
                                            { order: 0, value: 5, caption: 'x 5', selected: memeCannonStickersCount === 5 },
                                            { order: 1, value: 10, caption: 'x 10', selected: memeCannonStickersCount === 10 },
                                            { order: 2, value: 15, caption: 'x 15', selected: memeCannonStickersCount === 15 },
                                            { order: 3, value: 25, caption: 'x 25', selected: memeCannonStickersCount === 25 },
                                            { order: 4, value: 50, caption: 'x 50', selected: memeCannonStickersCount === 50 },
                                            { order: 5, value: 100, caption: 'x 100', selected: memeCannonStickersCount === 100 },
                                        ]}
                                        onSelectionChanged={handleMemeCannonStickersCountChange}
                                        onOutsideClick={onCannonCountOutsideClick}
                                    />
                                </div>
                            </Row>

                            <Row className={s.buttonWrapper}>
                                <div
                                    className={
                                        cn(
                                            s.button,
                                            s.buttonFavorites,
                                            s.buttonActive,
                                        )
                                    }
                                    onClick={onFavoritesClick}
                                >
                                    <div className={s.buttonContent}>
                                        {sticker.isFavorite
                                            ? (
                                                <IconHeartSolid className={cn(s.buttonIcon, s.buttonIconActive)}/>
                                            )
                                            : (
                                                <IconHeartLine className={s.buttonIcon}/>
                                            )
                                        }

                                        <div className={s.buttonText}>
                                            {sticker.likedCount ? getHumanReadableNumber(sticker.likedCount) : t('favorites')}
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </Column>
                    </>
                )
            }
        </div>
    );
};
