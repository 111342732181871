import { Button, Column, ERowJustification, Row } from '@hyperclap/ui';
import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IconImageResizeLandscapeLine, IconImageVLine, IconObs, IconPlayLine } from '@assets/images/svg';
import { declensionUserCurrency } from '@common';
import { AlertPosition, AlertType, FillType, IAlertTypeItem, IUser } from '@typings';
import { Alert } from '@views/fragments/Alert';


import { usePreview } from './hooks';
import s from './Preview.scss';
import { FieldColorPicker } from '../../AlertsSettingsType/components/Elements/components/FormSwitcher/components/FieldColorPicker';

interface IPreviewProps {
    alert: IAlertTypeItem;
    currentUser?: IUser;
    type: AlertType;
}

export const Preview = (props: IPreviewProps) => {
    const {
        alert,
        currentUser,
        type,
    } = props;
    const previewFrameRef = useRef<HTMLDivElement>(null);
    const {
        animationState,
        isOriginalSize,
        bgColor,
        randomSticker,
        onPreviewInClick,
        onObsTestClick,
        onOriginalSizeClick,
        onRelativeSizeClick,
        onBgColorChange,
    } = usePreview({
        alert,
        type,
    });
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.${id}`);
    const frameClassNameSize = isOriginalSize
        ? s[`previewSize-original`]
        : s[`previewSize-${alert.size}`];

    useEffect(() => {
        previewFrameRef?.current?.style?.setProperty('--animate-delay', '0');
    }, []);

    const variables = {
        name: currentUser?.name,
        value: 100,
        coins: declensionUserCurrency(100, currentUser),
        sticker: randomSticker?.name,
        text: t('textFromViewer'),
    };

    return (
        <div className={s.root}>
            <div
                className={cn(
                    s.previewFrame,
                    frameClassNameSize,
                )}
                ref={previewFrameRef}
                style={{
                    backgroundColor: bgColor,
                }}
            >
                <Alert
                    classNames={{ alert: s.previewAlert }}
                    alert={{
                        ...alert,
                        position: isOriginalSize
                            ? AlertPosition.CENTER
                            : alert.position,
                    }}
                    animationState={animationState}
                    imageUrl={currentUser?.avatar as string}
                    titleVariables={variables as unknown as Record<string, string>}
                    textVariables={variables as unknown as Record<string, string>}
                />
            </div>

            <Row
                className={s.controls}
                justification={ERowJustification.SPACE_BETWEEN}
            >
                <Column>
                    <Row>
                        <Button
                            className={cn(s.controlsItem, s.controlsItemButton)}
                            captionClassName={s.controlsItemButtonCaption}
                            caption={(
                                <IconPlayLine className={s.controlsItemButtonIcon}/>
                            )}
                            onClick={onPreviewInClick}
                        />

                        <Button
                            className={cn(s.controlsItem, s.controlsItemButton)}
                            captionClassName={s.controlsItemButtonCaption}
                            caption={(
                                <IconObs className={s.controlsItemButtonIcon}/>
                            )}
                            onClick={onObsTestClick}
                        />
                    </Row>
                </Column>

                <Column>
                    <Row>
                        <IconImageResizeLandscapeLine
                            className={cn(
                                s.controlsItem,
                                s.controlsItemIcon,
                                { [s.controlsItemIconActive]: !isOriginalSize },
                            )}
                            onClick={onRelativeSizeClick}
                        />

                        <IconImageVLine
                            className={cn(
                                s.controlsItem,
                                s.controlsItemIcon,
                                { [s.controlsItemIconActive]: isOriginalSize },
                            )}
                            onClick={onOriginalSizeClick}
                        />

                        <FieldColorPicker
                            isFlatOnly={true}
                            value={{ fillType: FillType.FLAT, primaryColor: bgColor }}
                            onChange={onBgColorChange}
                        />
                    </Row>
                </Column>
            </Row>
        </div>
    );
};
