import { noop } from '@hyperclap/utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { isStickerSentEvent } from '@common';
import { NotificationType } from '@contexts';
import { useMute, useNotifications, useWebSocket } from '@hooks';
import { EventsFilterPreset } from '@hooks/app/events';
import { useApi } from '@hooks/webapi';
import {
    EventKind,
    IDashboardPeriodData,
    IHookBaseParams,
    IStickerRelatedEvent,
    IStickerSentEvent,
    IUser,
    Period,
    TMemeAlertsEvent,
    TWSMessage,
    WSMessageType,
} from '@typings';


export interface IDashboardParams extends IHookBaseParams {
    currentUser?: IUser;
}

const PAGE_SIZE = 100;

const defaultDashboardData: IDashboardPeriodData = {
    daysInfo: [],
    periodTotal: { earnings: 0, supportersCount: 0 },
};

export const useDashboard = (params: IDashboardParams) => {
    const {
        active,
        currentUser,
    } = params;

    const {
        dashboard: {
            useLazyLoadDashboardDataQuery,
        },
        events: {
            useLazyLoadDashboardEventsQuery,
        },
        stickers: {
            useLazyToggleStickerDisableStateQuery,
            useApproveStickerOnStreamMutation,
            useDeclineStickerOnStreamMutation,
            useLazyLoadStickerQuery,
        },
    } = useApi();

    const {
        muteUser,
        unmuteUser,
    } = useMute({ streamerChannelName: currentUser?.id });

    const {
        notify,
    } = useNotifications();

    const {
        t,
    } = useTranslation();

    const { subscribe, unsubscribe } = useWebSocket();

    const [loadDashboardEvents, {
        isUninitialized: isDashboardEventsUninitialized,
        isFetching: isDashboardEventsFetching,
    }] = useLazyLoadDashboardEventsQuery();
    const [loadDashboardData] = useLazyLoadDashboardDataQuery();

    const [toggleStickerDisableState] = useLazyToggleStickerDisableStateQuery();
    const [approveStickerOnStream] = useApproveStickerOnStreamMutation();
    const [declineStickerOnStream] = useDeclineStickerOnStreamMutation();
    const [loadSticker, {
        isFetching: isStickerByIdFetching,
    }] = useLazyLoadStickerQuery();

    const [dashboardData, setDashboardData] = useState<IDashboardPeriodData>(defaultDashboardData);
    const [dashboardEvents, setDashboardEvents] = useState<Array<TMemeAlertsEvent>>([]);
    const [newDashboardEvents, setNewDashboardEvents] = useState<Array<TMemeAlertsEvent>>([]);
    const [period, setPeriod] = useState<Period>(Period.MONTH);
    const [eventsFilters, setEventsFilters] = useState<Array<EventsFilterPreset>>([
        EventsFilterPreset.PURCHASES,
        EventsFilterPreset.BONUSES,
        EventsFilterPreset.STICKERS,
        EventsFilterPreset.MEME_CANNON,
        EventsFilterPreset.FULLSCREEN,
        EventsFilterPreset.MODERATION,
    ]);

    const dashboardEventsRef = useRef(dashboardEvents);
    const newDashboardEventsRef = useRef(newDashboardEvents);

    const changePeriod = (val: Period) => setPeriod(val);
    const changeFilters = (val: Array<EventsFilterPreset>) => setEventsFilters(val);

    const updateApprovalStatus = (updatedEvent: IStickerRelatedEvent) => {
        setDashboardEvents([...dashboardEvents].map(
            (e) => isStickerSentEvent(e) && e._id === updatedEvent.id
                ? { ...e, isPreModerationNeeded: updatedEvent.isPreModerationNeeded, preModerationResult: updatedEvent.preModerationResult }
                : e,
        ));
        setNewDashboardEvents([...newDashboardEvents].map(
            (e) => isStickerSentEvent(e) && e._id === updatedEvent.id
                ? { ...e, isPreModerationNeeded: updatedEvent.isPreModerationNeeded, preModerationResult: updatedEvent.preModerationResult }
                : e,
        ));
    };

    const hideEvent = (updatedEvent: IStickerRelatedEvent) => {
        setDashboardEvents([...dashboardEvents].filter((e) => e._id !== updatedEvent.id));
        setNewDashboardEvents([...newDashboardEvents].filter((e) => e._id !== updatedEvent.id));
    };

    const toggleDisableState = async (event: IStickerSentEvent) => {
        const updatedEvent = (await toggleStickerDisableState(event._id).unwrap()) as IStickerSentEvent;
        setDashboardEvents([...dashboardEvents].map(
            (e) => isStickerSentEvent(e) && e.stickerId === updatedEvent.stickerId
                ? { ...e, isStickerDisabled: updatedEvent.isStickerDisabled }
                : e,
        ));
        setNewDashboardEvents([...newDashboardEvents].map(
            (e) => isStickerSentEvent(e) && e.stickerId === updatedEvent.stickerId
                ? { ...e, isStickerDisabled: updatedEvent.isStickerDisabled }
                : e,
        ));
    };

    const toggleMuteState = async (event: IStickerSentEvent) => {
        let muteValue: boolean;

        if (event.isUserMutedByStreamer) {
            await unmuteUser(event.userId);
            muteValue = false;
            notify(t('mute.user-is-unblocked'), NotificationType.SUCCESS);
        } else {
            await muteUser(event.userId);
            muteValue = true;
            notify(t('mute.user-is-blocked'), NotificationType.SUCCESS);
        }

        setDashboardEvents([...dashboardEvents].map(
            (e) => isStickerSentEvent(e) && e.userId === event.userId
                ? { ...e, isUserMutedByStreamer: muteValue }
                : e,
        ));
        setNewDashboardEvents([...newDashboardEvents].map(
            (e) => isStickerSentEvent(e) && e.userId === event.userId
                ? { ...e, isUserMutedByStreamer: muteValue }
                : e,
        ));
    };

    const approveOnStream = async (event: IStickerRelatedEvent) => {
        const updatedEvent = (await approveStickerOnStream(event._id).unwrap()) as IStickerRelatedEvent;
        updateApprovalStatus(updatedEvent);
    };

    const declineOnStream = async (event: IStickerRelatedEvent) => {
        const updatedEvent = (await declineStickerOnStream(event._id).unwrap()) as IStickerRelatedEvent;
        hideEvent(updatedEvent);
    };

    const onMessageHandler = (message: TWSMessage, callback: (data: unknown) => void = noop) => {
        const { type, data } = message;

        if (type === WSMessageType.EVENT_CREATED) {
            callback({ wasRead: true });
            const event = data.event;
            event.id = data.eventId ?? v4();
            event._id = event.id;

            if (data.event.kind === EventKind.STICKER_SENT||
                data.event.kind === EventKind.MEME_CANNON_STICKER_SENT) {
                (event as IStickerRelatedEvent).stickerId = data.event.stickerId;
            }

            if (
                !newDashboardEventsRef.current.some((e) => e._id === event._id) &&
                !dashboardEventsRef.current.some((e) => e._id === event._id)
            ) {
                setNewDashboardEvents([event].concat(newDashboardEventsRef.current));
            }
        }
    };

    useEffect(() => {
        dashboardEventsRef.current = dashboardEvents;
    }, [dashboardEvents]);

    useEffect(() => {
        newDashboardEventsRef.current = newDashboardEvents;
    }, [newDashboardEvents]);

    useEffect(() => {
        subscribe(currentUser?.id, onMessageHandler);

        return () => unsubscribe(currentUser?.id, onMessageHandler);
    }, [currentUser]);

    useEffect(() => {
        if (active && !isDashboardEventsFetching) {
            loadDashboardEvents({ limit: PAGE_SIZE, filters: eventsFilters }, false).unwrap().then((loadedDashboardEvents) => {
                setDashboardEvents(loadedDashboardEvents);
            });
        }
    }, [eventsFilters, active]);

    useEffect(() => {
        if (active) {
            loadDashboardData(period, false).unwrap().then((loadedDashboardData) => {
                setDashboardData(loadedDashboardData);
            });
        }
    }, [period, active]);

    return {
        dashboardData,
        dashboardEvents,
        newDashboardEvents,
        isDashboardEventsFetching,
        isDashboardEventsUninitialized,
        loadSticker,
        isStickerByIdFetching,
        changePeriod,
        changeFilters,
        toggleDisableState,
        toggleMuteState,
        approveOnStream,
        declineOnStream,
    };
};
