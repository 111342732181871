import React, { useEffect, useRef } from 'react';

import { EPopupTypes, IPopupInfo, IWelcomeBonusEarned } from '@hooks';
import { AlertType, IAlertTypeItem, IUser, TAlertsList } from '@typings';
import { Alert } from '@views/fragments/Alert';
import { DEFAULT_ALERT_STYLE } from '@views/pages/StreamerSettings/components/AlertSettings/const';


interface WelcomeBonusEarnedProps {
    currentUser?: IUser;
    popupInfo?: IPopupInfo;
    alertTypes: TAlertsList;
}

export const WelcomeBonusEarned = (props: WelcomeBonusEarnedProps) => {
    const {
        currentUser,
        popupInfo,
        alertTypes,
    } = props;

    const info = popupInfo as IWelcomeBonusEarned;
    const stickerAlertType = alertTypes.find((item) => item.type === AlertType.CURRENCY_PURCHASE) as IAlertTypeItem;
    const alert = info?.alert as IAlertTypeItem || stickerAlertType || DEFAULT_ALERT_STYLE;

    const variables = {
        name: info?.userName || currentUser?.name,
    };

    const soundPlayer = useRef<HTMLAudioElement>(null);

    const playSounds = () => {
        const player = soundPlayer.current;

        if (player && alert.soundUrl) {
            player.src = alert.soundUrl;

            if (alert.soundVolume) {
                player.volume = alert.soundVolume / 100;
            }

            void player.play();
        }
    };

    useEffect(() => {
        if (info?.type === EPopupTypes.WELCOME_BONUS_EARNED) {
            void playSounds();
        }
    }, [info]);

    return info?.type === EPopupTypes.WELCOME_BONUS_EARNED
        ? (
            <>
                <audio ref={soundPlayer} />

                <Alert
                    alert={alert}
                    imageUrl={info.userAvatar as string}
                    titleVariables={variables as unknown as Record<string, string>}
                    textVariables={variables as unknown as Record<string, string>}
                />
            </>
        )
        : null;
};
