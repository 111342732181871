import { Column, EComponentSize, ERowJustification, ITextFieldMethods, Loader, Paginator, Row, TextField } from '@hyperclap/ui';
import React, { useEffect, useRef } from 'react';

import { IconMagnifierLine, IconUserCircleSolid } from '@assets/images/svg';
import { isNotUndefined, supporterValueText } from '@common';
import { NotificationType } from '@contexts';
import { TApp, useNotifications } from '@hooks';
import { ISupporter, SupportersFilterPreset } from '@typings';
import { Empty, FilterButton } from '@views';

import s from './Desktop.scss';
import { SupportersTable } from '../../components';

interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        currentUser: {
            currentUser,
        },
        mute: {
            muteUser,
            unmuteUser,
        },
        navigation: {
            goToSupporter,
        },
        personalArea: {
            supporters: {
                isSupportersFetching,
                searchQuery,
                supporters,
                supportersCount,
                pagesCount,
                changePage,
                setSearchQuery,
                changeFilters,
                switchSupporterMute,
            },
        },
        translation: {
            t,
        },
    } = props;

    const { notify } = useNotifications();

    const SUPPORTERS_TYPE_FILTERS = [
        { caption: t('supporters.filter.all'), value: SupportersFilterPreset.ALL },
        { caption: t('supporters.filter.banned'), value: SupportersFilterPreset.BANNED },
    ];

    const searchFieldMethods = useRef<ITextFieldMethods>(null);

    const onSupportersPageChanged = (page: number) => changePage(page);

    const onSupporterSelected = (sup: ISupporter) => {
        goToSupporter(sup.supporterId);
    };

    const onMutedIconClick = async (supporter: ISupporter) => {
        if (supporter.mutedByStreamer) {
            await unmuteUser(supporter.supporterId);
            switchSupporterMute(supporter.supporterId);
        } else {
            await muteUser(supporter.supporterId);
            switchSupporterMute(supporter.supporterId);
        }

        notify(t(
            supporter.mutedByStreamer
                ? 'supporters.notification.user-unblocked'
                : 'supporters.notification.user-blocked',
        ), NotificationType.SUCCESS);
    };

    useEffect(() => {
        searchFieldMethods.current?.set(searchQuery);
    }, [searchQuery]);

    useEffect(() => {
        return () => setSearchQuery('');
    }, []);

    return (
        <Column grow margin={{ top: 26 }} rowGap={20}>
            <Row className={s.header} columnGap={10}>
                <Column rowGap={10}>
                    <Row>
                        {t('supporters.supporters')}
                    </Row>
                    <Row className={s.counterDescription}>
                        {supportersCount > 0 &&
                            <>{supportersCount} {supporterValueText(supportersCount)}</>
                        }
                    </Row>
                </Column>
                <Column grow/>
                <Column>
                    <TextField
                        debounceTime={500}
                        methodsRef={searchFieldMethods}
                        placeholder={t('supporters.find')}
                        tag={<IconMagnifierLine/>}
                        width={250}
                        className={s.supportersSearch}
                        onChanged={setSearchQuery}
                    />
                </Column>
                <Column margin={{ left: 7 }}>
                    <FilterButton
                        valuesList={SUPPORTERS_TYPE_FILTERS}
                        singleValueSelectionMode
                        initialSelection={[0]}
                        onSelectionChange={(val) => changeFilters(val.map((v) => v.value))}
                    />
                </Column>
            </Row>
            {pagesCount > 1 &&
                <Row justification={ERowJustification.END}>
                    <Paginator
                        page={1}
                        pagesCount={pagesCount}
                        maxDisplayedPages={10}
                        showNavigation
                        onChangePage={onSupportersPageChanged}
                    />
                </Row>
            }
            <Row>
                {currentUser &&
                    (isSupportersFetching
                        ? <Loader size={EComponentSize.MEDIUM}/>
                        : supporters?.length === 0
                            ? <Empty
                                padding={{ top: 70 }}
                                icon={<IconUserCircleSolid/>}
                                text={t('supporters.emptyText')}
                                iconSize={EComponentSize.EXTRA_LARGE}
                            />
                            : isNotUndefined(supporters)
                                ? <SupportersTable
                                    currentUser={currentUser}
                                    data={supporters}
                                    onSupporterClick={onSupporterSelected}
                                    onMutedIconClick={onMutedIconClick}
                                />
                                :null
                    )
                }
            </Row>
        </Column>
    );
};
