import { Button, EComponentColor, ITextFieldMethods, TextField, useClickOutside } from '@hyperclap/ui';
import cn from 'classnames';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconCloseLine } from '@assets/images/svg';

import s from './BonusPopup.scss';

const MAX_BONUS_SIZE = 1000;

interface IBonusPopupProps {
    price: number;
    className?: string;
    slim?: boolean
    onBonusSizeChange: (val: number) => void;
    onClose?: () => void;
    onSendBonusClick: () => void;
}

export const BonusPopup = forwardRef<HTMLDivElement, IBonusPopupProps>((props) => {
    const {
        slim,
        price,
        onBonusSizeChange,
        onClose,
        onSendBonusClick,
    } = props;

    const { t } = useTranslation();

    const bonusInputMethods = useRef<ITextFieldMethods>(null);
    const ref = useRef<HTMLFormElement>(null);

    useClickOutside({
        controlledElementRef: ref,
        handler: onClose,
    });

    function onKeyDown(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            onClose?.();
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, []);

    const [bonusBtnEnabled, setButtonBonusEnabled] = useState(false);
    const [bonusSize, setBonusSize] = useState(price || 10);

    function onSubmit(event: React.FormEvent): void {
        event.preventDefault();
        onSendBonusClick();
    }

    const onBonusValueChanged = (val: string) => {
        if (!val) {
            setBonusSize(0);

            return;
        }

        if (Number(val) > MAX_BONUS_SIZE) {
            bonusInputMethods?.current?.set(MAX_BONUS_SIZE.toString());
            setBonusSize(MAX_BONUS_SIZE);
        } else if (Number(val) <= 0) {
            bonusInputMethods?.current?.set((price || 10).toString());
            setBonusSize(price || 10);
        } else {
            setBonusSize(Number(val));
        }
    };

    useEffect(() => {
        setButtonBonusEnabled(bonusSize > 0 && bonusSize <= MAX_BONUS_SIZE);
        onBonusSizeChange(bonusSize);
    }, [bonusSize]);

    const className = cn(s.bonusWindow, { [s.slim]: slim }, props.className);

    return <form onSubmit={onSubmit} className={className} ref={ref}>
        <div className={s.label}>
            {t('supporters.increase-supporter-balance')}
        </div>
        <TextField
            className={s.field}
            invalidSymbols={/[^0-9]/i}
            autoFocus
            errorMessage={t('supporters.incorrect-value')}
            hasError={!bonusSize}
            defaultValue={(price || 10).toString()}
            methodsRef={bonusInputMethods}
            onChanged={onBonusValueChanged}
        />
        <Button
            type="submit"
            className={s.button}
            disabled={!bonusBtnEnabled}
            color={EComponentColor.ACCENT}
            caption={t('common.send-reward')}
        />
        <div className={s.closeButton} onClick={onClose}>
            <IconCloseLine/>
        </div>
    </form>;
});

BonusPopup.displayName = 'BonusPopup';
