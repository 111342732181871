import { EComponentSize, Icon } from '@hyperclap/ui';
import cn from 'classnames';
import { t } from 'i18next';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { ONE_MINUTE, ONE_SECOND } from '@common';
import { TMemeAlertsEvent } from '@typings';

import s from './EventBase.scss';

interface IBaseEventItemProps {
    event: TMemeAlertsEvent;
    animated?: boolean;
    color: string;
    icon: React.ReactElement;
    user: React.ReactNode;
    action: string;
    subject: React.ReactNode;
    message?: string;
    popup?: React.ReactNode;
}

export interface IEventItemProps<T extends TMemeAlertsEvent> {
    event: T;
    animated?: boolean;
}

const getDateTimeString = (event: TMemeAlertsEvent) => {
    const rightNow = Date.now();
    const diff = rightNow - event.timestamp;

    return diff < ONE_MINUTE
        ? t('common.now')
        : new Date(event.timestamp).toLocaleString('ru', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        }).replace(',', '');
};

const TEN_SECOND = 10 * ONE_SECOND;

export const BaseEvent = (props: PropsWithChildren<IBaseEventItemProps>) => {
    const {
        event,
        icon,
        color,
        user,
        action,
        message,
        subject,
        children,
        animated,
        popup,
    } = props;

    const eventRef = useRef(event);

    const [eventDateTime, setEventDateTime] = useState<string>(getDateTimeString(event));
    const [isHighlighted, setIsHighlighted] = useState(false);

    useEffect(() => {
        const rightNow = Date.now();
        const diff = rightNow - event.timestamp;
        let timer: NodeJS.Timeout;

        if (diff < TEN_SECOND) {
            setEventDateTime(getDateTimeString(event));
            setIsHighlighted(true);
            eventRef.current = event;
            timer = setTimeout(() => {
                setEventDateTime(getDateTimeString(eventRef.current));
                setIsHighlighted(false);
            }, TEN_SECOND - diff);
        }

        return () => clearTimeout(timer);
    }, [event]);

    const eventClassnames = cn(
        s.content,
        {
            [s.highlighted]: isHighlighted,
            [s.animated]: animated,
        },
        color,
    );

    return (
        <div className={s.event}>
            {popup}
            <div className={eventClassnames}>
                <Icon className={s.icon} icon={icon} size={EComponentSize.EXTRA_SMALL} />
                <div className={s.header}>
                    <div className={s.user}>{user}</div>
                    <div className={s.action}>{action}</div>
                    <div className={s.subject}>{subject}</div>
                </div>
                <div className={s.message}>
                    {message && <span>{message}</span>}
                </div>
                <div className={s.controls}>{children}</div>
                <div className={s.date}>{eventDateTime}</div>
            </div>
        </div>
    );
};
