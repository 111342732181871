import { ETheme } from '@hyperclap/ui';
import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import { ISticker } from '@typings';

import {
    Bottom,
    Content,
    Top,
} from './components';
import s from './StickerPopup.scss';
import { IStickerPopupDataExtended } from './types';


interface IStickerPopupProps extends IStickerPopupDataExtended {
    sticker?: ISticker;
    updateSticker(stickerData: ISticker): void;
    closeModal(): void;
}

export const StickerPopup = (props: IStickerPopupProps) => {
    const {
        sticker,
        closeModal,
        onClose,
        goToNextSticker,
        goToPrevSticker,
    } = props;
    const stickerRef = useRef(sticker);
    const extendedProps = {
        ...props,
        sticker,
        closeModal,
    } as IStickerPopupProps & { sticker: ISticker; };

    const handleWindowKeyDown = (event: KeyboardEvent) => {
        switch (event.key) {
            case 'Escape':
                closeModal();
                onClose?.();
                break;

            case 'ArrowRight':
            case 'ArrowDown':
                event.preventDefault();
                goToNextSticker?.(stickerRef.current);
                break;

            case 'ArrowLeft':
            case 'ArrowUp':
                event.preventDefault();
                goToPrevSticker?.(stickerRef.current);
                break;
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleWindowKeyDown);

        return () => window.removeEventListener('keydown', handleWindowKeyDown);
    }, []);

    useEffect(() => {
        stickerRef.current = sticker;
    }, [sticker]);

    return (
        <div className={cn(s.root, `theme-${ETheme.DARK}`)}>
            {sticker && (
                <>
                    <Top {...extendedProps}/>
                    <Content
                        {...extendedProps}
                        onPrevClick={goToPrevSticker && (() => goToPrevSticker(sticker))}
                        onNextClick={goToNextSticker && (() => goToNextSticker(sticker))}
                    />
                    <Bottom {...extendedProps}/>
                </>
            )}
        </div>
    );
};
