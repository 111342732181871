import { Button, Column, EColumnAlignment, EComponentColor, ERowJustification, Image, Row } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ImageNotSent } from '@assets/images/jpg';
import { IconCloseLine } from '@assets/images/svg';

import s from './NotSentPopup.scss';

interface INotSentPopupProps {
    mobileLayout?: boolean;
    onCloseClick: () => void;
}

export const NotSentPopup = (props: INotSentPopupProps) => {
    const onClose = (e: MouseEvent): void => {
        e.stopPropagation();
        props.onCloseClick();
    };

    const { t } = useTranslation();

    return (
        <div className={s.shadow} onClick={(e) => onClose(e.nativeEvent)}>
            <Column
                width={446}
                padding={20}
                rowGap={20}
                className={cn(s.popup, { [s.popupMobileLayout]: props.mobileLayout })}
                alignment={EColumnAlignment.CENTER}
            >
                <Row>
                    <Image source={ImageNotSent} height={190} className={s.image} />
                </Row>
                <Row className={s.text} justification={ERowJustification.CENTER}>
                    {t('premoderation.not-sent-1')}
                </Row>
                <Row className={s.text} justification={ERowJustification.CENTER}>
                    {t('premoderation.not-sent-2')}
                </Row>
                <Row>
                    <Button color={EComponentColor.ACCENT} caption={t('common.clear-to-me')} onClick={(e) => onClose(e.nativeEvent)}/>
                </Row>

                <div className={s.closeButton} onClick={props.onCloseClick}><IconCloseLine/></div>
            </Column>
        </div>
    );
};
