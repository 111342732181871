import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EAuthMode, initialAppState, StickerSendResult } from '@typings';

const appSlice = createSlice({
    name: 'app',
    initialState: initialAppState,
    reducers: {
        updateMode: (state, action: PayloadAction<EAuthMode>) => {
            state.mode = action.payload;
        },
        switchPlaySoundOnStickerHover: (state) => {
            state.playSoundOnStickerHover = !state.playSoundOnStickerHover;
        },
        setIsStreamerPageFloatingHeaderVisible: (state, action: PayloadAction<boolean>) => {
            state.isStreamerPageFloatingHeaderVisible = action.payload;
        },
        setStickerSendResult: (state, action: PayloadAction<StickerSendResult>) => {
            state.stickerSendResult = action.payload;
        },
        resetStickerSendResult: (state) => {
            state.stickerSendResult = StickerSendResult.UNKNOWN;
        },
        setSendButtonAnimationActive: (state, action: PayloadAction<boolean>) => {
            state.isSendButtonAnimationActive = action.payload;
        },
    },
});

export const appSliceActions = appSlice.actions;
export const appSliceReducer = appSlice.reducer;
