import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';

import { IconDollarLine } from '@assets/images/svg';
import { declensionUserCurrency } from '@common';
import { EventKind, IBonusEarnedEvent, IBonusIssuedEvent, IUser } from '@typings';

import { BaseEvent, IEventItemProps } from './EventBase';
import s from './EventBase.scss';

interface IBonusEarnedProps extends IEventItemProps<IBonusEarnedEvent | IBonusIssuedEvent> {
    currentUser?: IUser;
}

export const EventBonus = ({ currentUser, event, animated }: IBonusEarnedProps) => {
    const subject = currentUser
        ? `${event.bonusValue} ${declensionUserCurrency(event.bonusValue, currentUser).toLowerCase()}`
        : '';
    const isEarned = event.kind === EventKind.BONUS_EARNED;
    const to = `/supporters/${event.userId}`;

    return (
        <BaseEvent
            animated={animated}
            event={event}
            color={s.blue}
            icon={<IconDollarLine />}
            user={<Link to={to}>{event.userName || t('common.anonymous')}</Link>}
            action={isEarned ? t('events.earned-bonus') : t('events.sent-bonus')}
            subject={subject}
        />
    );
};
