import { delay } from '@hyperclap/utils';
import { useState } from 'react';

import { useApi } from '@hooks';
import { AlertType, IAlertTypeItem, IFillStyle } from '@typings';
import { EAnimationState } from '@views/fragments/Alert';

interface IUsePreviewParams {
    alert: IAlertTypeItem;
    type: AlertType;
}

const DEFAULT_BG_COLOR = '#2093F1';

export const usePreview = (params: IUsePreviewParams) => {
    const {
        alert,
        type,
    } = params;
    const {
        stickers: {
            useSendRandomStickerMutation,
            useLoadRandomStickerQuery,
        },
        alerts: {
            useSendTestBonusEarnedMutation,
            useSendTestBuyCurrencyMutation,
            useSendTestNewSupporterMutation,
        },
    } = useApi();
    const { data: randomSticker, isSuccess: isRandomStickerLoadSuccess } = useLoadRandomStickerQuery();
    const [sendRandomSticker] = useSendRandomStickerMutation();
    const [sendTestBonusEarned] = useSendTestBonusEarnedMutation();
    const [sendTestBuyCurrency] = useSendTestBuyCurrencyMutation();
    const [sendTestNewSupporter] = useSendTestNewSupporterMutation();
    const [animationState, setAnimationState] = useState(EAnimationState.NONE);
    const [isOriginalSize, setIsOriginalSize] = useState(false);
    const [bgColor, setBgColor] = useState(DEFAULT_BG_COLOR);
    const isLoading = !isRandomStickerLoadSuccess;

    const onPreviewInClick = async () => {
        setAnimationState(EAnimationState.NONE);

        await delay(0);

        setAnimationState(EAnimationState.OUT);

        await delay(2000);

        setAnimationState(EAnimationState.IN);
    };

    const onPreviewOutClick = async () => {
        setAnimationState(EAnimationState.NONE);

        await delay(0);

        setAnimationState(EAnimationState.OUT);
    };

    const onObsTestClick = async () => {
        switch (type) {
            case AlertType.STICKER:
                await sendRandomSticker({
                    fullscreen: false,
                    alert,
                });
                break;
            case AlertType.BONUS:
                await sendTestBonusEarned({
                    value: Math.round(Math.random() * 10),
                    alert,
                });
                break;
            case AlertType.CURRENCY_PURCHASE:
                await sendTestBuyCurrency({
                    value: Math.round(Math.random() * 100),
                    alert,
                });
                break;
            case AlertType.NEW_SUPPORTER:
                await sendTestNewSupporter({
                    alert,
                });
                break;
        }
    };

    const onRelativeSizeClick = () => setIsOriginalSize(false);

    const onOriginalSizeClick = () => setIsOriginalSize(true);

    const onBgColorChange = (value: IFillStyle) => setBgColor(value?.primaryColor);

    return {
        animationState,
        isOriginalSize,
        bgColor,
        randomSticker,
        isLoading,

        onPreviewInClick,
        onPreviewOutClick,
        onObsTestClick,
        onRelativeSizeClick,
        onOriginalSizeClick,
        onBgColorChange,
    };
};
